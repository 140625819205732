import React from 'react';
import LanguageContext from '../../LanguageContext';



class SelectRoomButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        

    }
}

static contextType = LanguageContext;

render() {

const { translate } = this.context;


    let priceBlock = '';
    if (this.props.discountedNightPrice>0){
        priceBlock = (<div className="d-flex align-items-center" style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 40 + 'px'}}>
        <span className="text-decoration-line-through">{this.props.nightPrice}₪</span>
        <h5 className="fw-normal mb-0 me-1" style={{color:'#e850d6', fontSize: 30 + 'px'}}>{this.props.discountedNightPrice}₪</h5>
        <span className="mb-0 me-2">/night</span>
        
    </div>)

    }
  

    return(
        <>
              {/*<!-- Price -->*/}
              
        {priceBlock}
           {/*<!-- Button -->*/}
              <br />
        {this.props.discountedNightPrice>0 && (     
        <div className="col-12 mt-2 mt-sm-0" style={{position: 'absolute',
            width: 90 +'%',
            left: 5 +'%',
            bottom: 10 +'px'}}>
            <a href={"roomdetails?room=" + this.props.roomId + '&checkin=' + this.props.checkIn + '&checkout=' + this.props.checkOut + '&adults=' + this.props.adults + '&children=' + this.props.children + '&pets=' + this.props.pets } 
            className="btn btn-sm btn-primary-soft mb-0 w-100">
                {translate('selectThisPalace')}
                <i className="bi bi-arrow-right ms-2"></i>
            </a>    
        </div>
        )}
        
        </>
  
    );
  }
}




export default SelectRoomButton;











