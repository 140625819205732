
import React from 'react';
import SearchBar from '../Components/SearchBar/SearchBar';
import '../App.css';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
//import '../App.css'
import Carousel from 'react-bootstrap/Carousel';
import config from '../config';
import LanguageContext from '../LanguageContext';



//const apiUrl = process.env.REACT_APP_TITLE;
const imagesUrl = config.imagesUrl;

class Home extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			movie: '6as6eLtWOjI',
		  images:[
			  {index: 1 , src: imagesUrl + '/images/princepalace_telavivcenter_penthouse_masterbedroom1.jpg', el:'img'},
			  {index: 2 , src: imagesUrl + '/images/PrincePalaceNetanyaBeach0001.jpg', el:'img2'},
			  {index: 3 , src: imagesUrl + '/images/princepalace_haderabeach_goldpalace_lovingroom1.jpg', el:'img3'},
		  ],
		  locations:[{index:1, src:'assets/images/category/hotel/01.jpg', price:'$499', name:'Tel Aviv Center'},
		  {index:2, src:'assets/images/category/hotel/02.jpg', price:'$199', name:'Netanya Beach'},
		  {index:3, src:'assets/images/category/hotel/03.jpg', price:'$399', name:'Hadera Beach'},
		  ],
		  cardStyle: 'border: 2px solid deeppin'
	  };
	  
	  }

	  static contextType = LanguageContext;

    render() {

		const { translate } = this.context;
        return (
            <>
			<div className="mainBannerDesktop" style={{display: 'flex'}}>
								<div style={{width: 33.3 + '%'}}>
									<div className="text-white">
										<img className="card-img" src={imagesUrl + "images/princepalace_telavivcenter_penthouse_masterbedroom1.jpg"} alt="Prince Palace Tel Aviv" style={{width:100 +'%'}} />
										<div className=" banerText" >
											<h5 className="card-title">{translate('telAvivCenter')}</h5>
								
										</div>
									</div>
								</div>
								<div style={{width: 33.3 + '%'}}>
									<div className="text-white">
										<img className="card-img" src={imagesUrl + "images/PrincePalaceNetanyaBeach0001.jpg"} alt="Prince Palace Netanya Beach" style={{width:100 +'%'}} />
										<div className=" banerText">
											<h5 className="card-title">{translate('netanyaSouthBeach')}</h5>
								
										</div>
									</div>
								</div>
								<div style={{width: 33.3 + '%'}}>
									<div className="text-white">
										<img className="card-img" src={imagesUrl + "images/princepalace_haderabeach_goldpalace_lovingroom1.jpg"} alt="Prince Palace Hadera Beach" style={{width:100 +'%'}} />
										<div className=" banerText" >
											<h5 className="card-title">{translate('haderaBeach')}</h5>
								
										</div>
									</div>
								</div>
					</div>
					
			<div className="mainBannerMobile">
				<Carousel>
					{this.state.images.map((el, index) => (
						<Carousel.Item key={index}>
							<img
							className="d-block w-100"
							src={el.src}
							alt=""
							/>
							<Carousel.Caption>
							<h3>{''}</h3>
							<p>{''}</p>
							</Carousel.Caption>
						</Carousel.Item>
						))}
    			</Carousel>
			</div>

                            <SearchBar />

                          

                            {/*   <!-- =======================
Featured Hotels START /--/*/}
<section style={{marginTop: -80 + 'px'}}>
	<div class="container">

		{/*<!-- Title -->*/}
		<div className="row mb-4">
			<div className="col-12 text-center">
				<h3 className="mb-0">{translate('luxuryApartmentsInIsrael')}</h3><h5>{translate('primeLocation')}</h5>
			</div>
		</div>
		<div className="prmieLocations">
			<div className="row" style={{display:'flex'}}>
			<div className="locationBox col-sm-12 col-xl-12" style={{height:300 + 'px'}}>

					<div className="card card-img-scale overflow-hidden bg-transparent" style={{height:300 + 'px'}}>
					<a href="/Netanya-South-Beach" className="stretched-link">

						<div className="card-img-scale-wrapper rounded-3" style={{height:300 + 'px'}}>

							<img src={imagesUrl + "images/PrincePalaceNetanyaBeach0001.jpg"} class="card-img" alt="hotel image" style={{height:300 + 'px'}}/>
							<div className="locationBudge">

							<i className="bi bi-geo-alt me-2"></i>{translate('netanyaSouthBeach')}
							</div>
						</div>
						</a>

					</div>
					

				</div>	
				<div className="locationBox col-sm-12 col-xl-4" style={{height:300 + 'px'}}>
					<div className="card card-img-scale overflow-hidden bg-transparent" style={{height:300 + 'px'}}>
					<a href="/Tel-Aviv-Center" className="stretched-link">

						<div className="card-img-scale-wrapper rounded-3" style={{height:300 + 'px'}}>
							<img src={imagesUrl + "images/locations/tel_aviv_location.jpg"} class="card-img" alt="hotel image" style={{height:300 + 'px'}}/>
							<div className="locationBudge">
							<i className="bi bi-geo-alt me-2"></i>{translate('telAvivCenter')}
							</div>
						</div>
						</a>
					</div>
				</div>
				<div className="locationBox col-sm-12 col-xl-8" style={{height:300 + 'px'}}>
					<div className="card card-img-scale overflow-hidden bg-transparent" style={{height:300 + 'px'}}>
					<a href="/Hadera-White-Beach" className="stretched-link">

						<div className="card-img-scale-wrapper rounded-3" style={{height:300 + 'px'}}>
							<img src={imagesUrl + "images/locations/hadera_beach.jpg"} class="card-img" alt="hotel image" style={{height:300 + 'px'}}/>
							<div className="locationBudge">
							<i className="bi bi-geo-alt me-2"></i>{translate('haderaBeach')}
							</div>
						</div>
						</a>
					</div>
				</div>			
				
				
				</div>
			
		</div>







		<div className="locationBaners">
						<div className="row g-4">
	

				{/*<!-- Hotel item -->*/}
				<div className="col-sm-4 col-xl-4">
					{/*<!-- Card START -->`*/}
					<div className="card card-img-scale overflow-hidden bg-transparent">
						{/*<!-- Image and overlay -->*/}
						<div className="card-img-scale-wrapper rounded-3">
							{/*<!-- Image -->*/}
							<img src={imagesUrl + "/images/locations/netanya_building.jpg"} className="card-img" alt="Prince Palace Netanya South Beach" />
							{/*<!-- Badge -->*/}
							<div className="position-absolute bottom-0 start-0 p-3">
								<div className="badge text-bg-dark fs-6 rounded-pill stretched-link"><i className="bi bi-geo-alt me-2"></i>{translate('netanyaSouthBeach')}</div>
							</div>
						</div>

						{/*<!-- Card body -->*/}
						<div className="card-body px-2">
							{/*<!-- Title -->*/}
							<h5 className="card-title"><a href="/Netanya-South-Beach" className="stretched-link">Prince Palace Netanya South Beach</a></h5>
							{/*<!-- Price and rating -->*/}
							<div className="d-flex justify-content-between align-items-center">
								<h6 className="text-success mb-0"> <small className="fw-light">Starting at $199 / Night</small> </h6>
								<h6 className="mb-0">4.8<i className="fa-solid fa-star text-warning ms-1"></i></h6>
							</div>
						</div>
					</div>
				{/*	<!-- Card END --> */}
				</div>
			{/*<!-- Hotel item -->*/}
			<div className="col-sm-4 col-xl-4">
					{/*<!-- Card START --> */}
					<div className="card card-img-scale overflow-hidden bg-transparent">
						{/*<!-- Image and overlay -->*/}
						<div className="card-img-scale-wrapper rounded-3">
							{/*<!-- Image -->*/}
							<img src={imagesUrl + "/images/locations/hadera_building.jpg"} class="card-img" alt="Prince Palace Hadera Beach" />
							{/*<!-- Badge -->*/}
							<div className="position-absolute bottom-0 start-0 p-3">
								<div className="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>{translate('haderaBeach')}</div>
							</div>
						</div>

						{/*<!-- Card body -->*/}
						<div className="card-body px-2">
							{/*<!-- Title -->*/}
							<h5 className="card-title"><a href="/Hadera-White-Beach" class="stretched-link">Prince Palace Hadera Beach</a></h5>
							{/*<!-- Price and rating -->*/}
							<div className="d-flex justify-content-between align-items-center">
								<h6 className="text-success mb-0"><small class="fw-light">tarting at $216/night</small> </h6>
								<h6 className="mb-0">4.9<i className="fa-solid fa-star text-warning ms-1"></i></h6>
							</div>
						</div>
					</div>
					{/*<!-- Card END -->*/}
				</div>
				{/*<!-- Hotel item -->*/}
				<div className="col-sm-4 col-xl-4">
					<div className="card card-img-scale overflow-hidden bg-transparent">
						<div className="card-img-scale-wrapper rounded-3">
							<img src={imagesUrl + "/images/locations/tel_aviv_building.jpg"} className="card-img" alt="Prince Palace Tel Aviv" />
							{/*<!-- Badge --> */}
							<div className="position-absolute bottom-0 start-0 p-3">
								<div className="badge text-bg-dark fs-6 rounded-pill stretched-link"><i class="bi bi-geo-alt me-2"></i>{translate('telAvivCenter')}</div>
							</div>
						</div>

						<div className="card-body px-2">
							<h5 className="card-title"><a href="/Tel-Aviv-Center" className="stretched-link">Prince Palace Tel Aviv Center</a></h5>
							<div className="d-flex justify-content-between align-items-center">
								<h6 className="text-success mb-0"><small className="fw-light">Starting at $346/Night</small> </h6>
								<h6 className="mb-0">5.0<i className="fa-solid fa-star text-warning ms-1"></i></h6>
							</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>
	


	</div>
</section>
    
                            </>   

        );
    }
}

export default Home;