import React from 'react';
import './BarSelections.css';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../LanguageContext';

/*
function Navigation2(searchParameters) {
    //const navigation = useNavigation();
    const navigate = useNavigate();
    //navigate('/Netanya-South-Beach?checkin=' + searchParameters.checkIn + '&checkout=' + searchParameters.checkOut + '&adults=' + searchParameters.state.adults);
    useEffect(() => {
        navigate('/Netanya-South-Beach?checkin=' + searchParameters.checkIn + '&checkout=' + searchParameters.checkOut + '&adults=' + searchParameters.state.adults);
      });
  }

 */

  const SearchButton = (props) => {

    const { translate } = useLanguage();

    const searchVacation = () => {
        let searchParameters ={
            locationId: props.locationId,
            selectedLocation: props.selectedLocation,
            checkIn: props.checkIn,
            checkOut: props.checkOut,
            adults:  props.adults,
            children: props.children,
            pets: props.pets,
        }
        let linkSwitch = () => {
            switch(props.locationId) {
                case 0:
                    return 'Israel';
                case 1:
                    return 'Tel-Aviv-Center';

                case 2:
                    return 'Netanya-South-Beach';
                case 3:
                    return 'Hadera-White-Beach';
                case 4:
                    return 'Herzelia-Pituach-Beach';    
              default:
                return 'Israel';
            }
        };




        let navigate = '/' + linkSwitch() + '?checkin=' + searchParameters.checkIn + '&checkout=' + searchParameters.checkOut + '&adults=' + searchParameters.adults + '&children=' + searchParameters.children + '&pets=' + searchParameters.pets;

      
        window.location.assign(navigate);

        //Navigation2(searchParameters);
    };


    return (
        <button className="searchButton" onClick={searchVacation}>
                    {translate('search')}
        </button>

    );


}

export default SearchButton;
