import React from 'react';
import { useLanguage } from './LanguageContext';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { changeLanguage, supportedLanguages } = useLanguage();

  const handleLanguageChange = (newLanguage) => {
    changeLanguage(newLanguage);
  };

  return (
    <div className="language-switcher">
      {supportedLanguages.map((lang) => (
        <button className="flagButton"   key={lang} onClick={() => handleLanguageChange(lang)}>
          
          <img src={'/assets/images/flagicons/flag-' + lang + '.png'} alt={lang.toUpperCase()} width="30px"/>
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
  
