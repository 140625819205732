import React, { useState } from 'react';
import Cards from 'react-credit-cards-2';
//import 'react-credit-cards-2/dist/lib/styles.scss';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import '../booking.css';


import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData
  } from "./utilis";


const CreditCard = () => {
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
        id: '',
        issuer: ''
      });
    
/*
      handleInputChange = ({ target }) => {
        if (target.name === "number") {
          target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
          target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
          target.value = formatCVC(target.value);
        }
    
        this.setState({ [target.name]: target.value });
      };
*/

      const handleInputChange = (e) => {
       let { name, value } = e.target;
       if (name === "number") {
        value = formatCreditCardNumber(value);
        } 
        if (name === "expiry") {
            value = formatExpirationDate(value);
            }
            if (name === "cvc") {
                value = formatCVC(value);
                }    
        setState((prev) => ({ ...prev, [name]: value }));
      }

      const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
      }
      
      return (
        <div>
          <Cards
            number={state.number}
            expiry={state.expiry}
            cvc={state.cvc}
            name={state.name}
            focused={state.focus}
          />
          <form>
          <div className="form-group">
            <input
              type="tel"
              name="number"
              className="form-control"
              placeholder="Card Number"
                pattern="[\d| ]{16,22}"
                required
              value={state.number}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              maxLength={19}
            />
            </div>
             <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="id"
                className="form-control"
                placeholder="ID or Passport number"
                required
                maxLength={10}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
            <div className="row" style={{padding: 10 +'px'}}>
              <div className="col-6">
                <input
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  maxLength={5}
                  value={state.expiry}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  required
                  maxLength={4}
                  value={state.cvc}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
            </div>

            <input type="hidden" name="issuer" value={state.issuer} />
            <div className="form-actions">
              <button className="btn btn-primary btn-block" style={{width: 98 + '%', margin: 'auto', marginLeft: 1 + '%', backgroundColor: '#3ca8be'}}>PAY</button>
            </div>
          </form>
        </div>
      );
}

export default CreditCard;