import React from 'react';
import './BarSelections.css';
import SmarketDateRange from '../SmarketDateRange';
//import TestDateRange from '../TestDateRange';

import GuestSelection from './GuestsSelection';
import LocationSelect from './LocationSelect';
import SearchButton from './SearchButton';
import LanguageContext from '../../LanguageContext';



class SearchBar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        locationId: 0,
        selectedLocation: 'Israel',
        adults: 1,
        children: 0,
        pets: 0,
        guestsTxt: '',
        checkIn: null,
        checkOut: null,
        calanderPages:1
    };
     this.handleLocation = this.handleLocation.bind(this);
     this.handleDates = this.handleDates.bind(this);
    }
 
    //navigate = useNavigate();


    handleLocation = (locationId) => {
        //this.setState({locationId: locationId})
        this.setState({
            locationId: locationId
          })
    }

    
    formatDate(date) {
        if (date === null) {
            // Handle the case when the date is null
            return '';
          }
        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
          }
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear()
        ].join('/');
      }

    handleDates = (date) => {


        this.setState({
            checkIn: this.formatDate(date[0]), checkOut: this.formatDate(date[1])
          });
    }

    handleGuest = (data) => {
        this.setState({
            adults: data.adults,
            children: data.children,
            pets: data.pets,
            guestsTxt: data.selectedGuestsTxt
        })
    }

    componentDidMount() {
        const windowWidth = window.innerWidth;
        if (windowWidth> 600){
            this.setState({calanderPages: 2});
        }
      }
   

	  static contextType = LanguageContext;

    render() {

		const { translate } = this.context;
        return ( 
            <div className="searchContainer" >
              
                <div className="row" >
                    <div className="col-12 col-sm-4" >
                    
                        <div className="searchContainerDiv">{translate('location')}</div>
                        <div><LocationSelect onSelectLocation={this.handleLocation}/></div>
                    </div>
                    <div className="col-12 col-sm-4" >
                        <div className="searchContainerDiv">{translate('date')}</div>
                        <div className="form-select-dates">
                            <SmarketDateRange onSelectDates={this.handleDates} calanderPages={this.state.calanderPages}/>
                        </div>

                    </div>

                    <div className="col-12 col-sm-4" >
                        <div className="searchContainerDiv">{translate('guests')}</div>
                        <div className='form-select-guests' aria-label='Select Guests'>
                            <GuestSelection onSubmitNumberOfGuests={this.handleGuest} />
                        </div>
                    </div>
                    <div className="col-12">
                        <SearchButton   locationId={this.state.locationId}
                                        selectedLocation={this.state.selectedLocation}
                                        checkIn={this.state.checkIn}
                                        checkOut={this.state.checkOut}
                                        adults={this.state.adults}
                                        children={this.state.children}
                                        pets={this.state.pets} />
                    </div>



                </div>
               

               
            </div>



        );}
}


export default SearchBar;



            