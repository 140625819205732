
import React from 'react';
//import { useState } from 'react';
import MapCreator from './mapCreator';
import UploadImages from './UploadImages';

class AddNewMalon extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        activeStep: 1,
        stepStatus: ['step active', 'step', 'step', 'step'], 
        stepTab: [' active dstepper-block', '', '', ''],
        displayTab: ['block', 'none', 'none', 'none'],
        country: '',
        state: '',
        city: '',
        street: '',
        };

        this.selectStep = this.selectStep.bind(this);
        this.handleSbumit = this.handleSubmit.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    selectStep(number){
        //set stepStatus
            console.log(number);
            let tempStatus = ['step', 'step', 'step', 'step'];
            tempStatus[number-1] = 'step active';
            this.setState({stepStatus: tempStatus});
        //set stepTab
            let tempStepTab = ['', '', '', ''];
            tempStepTab[number-1] = ' active dstepper-block';
            this.setState({stepTab: tempStepTab});
            let tempDisplay = ['none', 'none', 'none', 'none'];
            tempDisplay[number-1] = 'block';
            this.setState({displayTab: tempDisplay});

    }
    handleSelect(index){
        console.log('Selected tab: ' + index);
      }
    handleSubmit = () => {
        console.log('handle Submit');
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
      }

  

    moveStep = (stepNumber) => {
        
    }
    render(){
        return(<>
            <main>  
            

            <section className="pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="fs-2 mb-2">Add New Listing</h1>
                                <p className="mb-0">Praise effects wish change way and any wanted. Lively use looked latter regard had.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div id="stepper" className="bs-stepper stepper-outline">
                
                            {/*<!-- Step Buttons START --> */}
                            <div className="bs-stepper-header" role="tablist">
                                <div className={this.state.stepStatus[0]} data-target="#step-1">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger1" aria-controls="step-1" onClick={() => this.selectStep(1)}>
                                            <span className="bs-stepper-circle">1</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Property Type</h6>
                                    </div>
                                </div>
                                <div className="line"></div>
                
                                <div className={this.state.stepStatus[1]} data-target="#step-2">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" id="steppertrigger2" aria-controls="step-2"  onClick={() => this.selectStep(2)}>
                                            <span className="bs-stepper-circle">2</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Basic Information</h6>
                                    </div>
                                </div>
                                <div className="line"></div>
                
                                <div className={this.state.stepStatus[2]} data-target="#step-3">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger3" aria-controls="step-3" onClick={() => this.selectStep(3)}>
                                            <span className="bs-stepper-circle">3</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Detailed Information</h6>
                                    </div>
                                </div>
                                <div className="line"></div>
                
                                <div className={this.state.stepStatus[3]} data-target="#step-4">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger4" aria-controls="step-4" onClick={() => this.selectStep(4)}>
                                            <span className="bs-stepper-circle">4</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Price & Policy</h6>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- Step Buttons END --> */}
                
                            {/*<!-- Step content START --> */}
                            <div className="bs-stepper-content p-0 pt-4 pt-md-5">
                                <div className="row g-4">
                
                                    <div className="col-12">
                                        <form onSubmit={this.handleSubmit}>
                
                                            <div id="step-1" role="tabpanel" className={'content fade ' + this.state.stepTab[0]} aria-labelledby="steppertrigger1" style={{display: `${this.state.displayTab[0]}`}}>
                                                <div className="vstack gap-4">
                                                    <h4 className="mb-0">Property Type</h4>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Choose Listing Category</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                <div className="col-12">
                                                                    <label className="form-label">Choose listing type *</label>
                                                                    <select className="form-select js-choice" onChange={this.handleChange}>
                                                                        <option value="">Select type</option>
                                                                        <option>Hotel</option>
                                                                        <option>Villa</option>
                                                                        <option>Home Stay</option>
                                                                        <option>Farmhouse</option>
                                                                        <option>House boat</option>
                                                                    </select>
                                                                    <small>Hotel: Professional hospitality businesses that usually have a unique style or theme defining their brand and decor</small>
                                                                </div>
                
                                                                
                
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    
                
                                                    <div className="text-end">
                                                        <button className="btn btn-primary next-btn mb-0">Next</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="step-2" role="tabpanel" className={'content fade ' + this.state.stepTab[1]} aria-labelledby="steppertrigger2" style={{display: `${this.state.displayTab[1]}`}}>
                                                <div className="vstack gap-4">
                                                    <h4 className="mb-0">Basic Information</h4>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Name your list</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                
                                                                <div className="col-12">
                                                                    <label className="form-label">Listing name *</label>
                                                                    <input className="form-control" type="text" placeholder="Enter place name" />
                                                                    <small>A catchy name usually includes: House name - Room name - A tourist destination</small>
                                                                </div>
                
                                                                <div className="col-12">
                                                                    <label className="form-label">Is your listing set as a personal or guest use *</label>
                                                                    <div className="d-sm-flex">
                                                                        <div className="form-check radio-bg-light me-4">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Entire Place
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check radio-bg-light me-4">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                For Guest
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check radio-bg-light">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                                For Personal
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                                                                <div className="col-12">
                                                                    <label className="form-label">Short description *</label>
                                                                    <textarea className="form-control" rows="2" placeholder="Enter keywords"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Listing Location</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-3">
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Country/Region *</label>
                                                                    <select className="form-select js-choice" name="country" value={this.state.country} onChange={this.handleInputChange}>
                                                                        <option value="">Select Country</option>
                                                                        <option>India</option>
                                                                        <option>Usa</option>
                                                                        <option>Japan</option>
                                                                        <option>United Kingdom</option>
                                                                    </select>
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">State *</label>
                                                                    <select className="form-select js-choice" data-search-enabled="true" name="state" value={this.state.state} onChange={this.handleInputChange}>
                                                                        <option value="">Select state</option>
                                                                        <option>India</option>
                                                                        <option>Usa</option>
                                                                        <option>Japan</option>
                                                                        <option>United Kingdom</option>
                                                                    </select>
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">City *</label>
                                                                    <input className="form-control" type="text" placeholder="Enter city" name="city" value={this.state.city} onChange={this.handleInputChange}/>
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Postal number *</label>
                                                                    <input className="form-control" type="text" placeholder="Enter postal number" />
                                                                </div>
                
                                                                <div className="col-12">
                                                                    <label className="form-label">Street *</label>
                                                                    <input className="form-control" type="text" placeholder="Enter street" name="street" value={this.state.street} onChange={this.handleInputChange}/>
                                                                </div>
                                                                <MapCreator country={this.state.country} city={this.state.city} street={this.state.street}/>

                
                                            
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Upload Images</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-3">
                                                                <div className="col-12">
                                                                    <label className="form-label">Upload thumbnail image *</label>
                                                                    <input className="form-control" type="file" name="my-image" id="image" accept="image/gif, image/jpeg, image/png" />
                                                                    <p className="small mb-0 mt-2"><b>Note:</b> Only JPG, JPEG, and PNG. Our suggested dimensions are 600px * 450px. The larger image will be cropped to 4:3 to fit our thumbnails/previews.</p>
                                                                </div>
                
                                                                
                                                                <UploadImages />
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="text-end">
                                                        <button className="btn btn-primary next-btn mb-0">Next</button>
                                                    </div>
                                                </div>
                                            </div>
                
                                            <div id="step-3" role="tabpanel" className={'content fade ' + this.state.stepTab[2]} aria-labelledby="steppertrigger3" style={{display: this.state.displayTab[2]}}>
                                                <div className="vstack gap-4">
                                                    <h4 className="mb-0">Detailed Information</h4>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Overview</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                <div className="col-12">
                                                                    <label className="form-label">Select amenities *</label>
                                                                    <select className="form-select js-choice border-0 z-index-9 bg-transparent" multiple="multiple" aria-label=".form-select-sm" data-max-item-count="15" data-remove-item-button="true">
                                                                        <option value="">Select amenities</option>
                                                                        <option>Swimming pool</option>
                                                                        <option>Spa</option>
                                                                        <option>Kid's play area</option>
                                                                        <option>Gym</option>
                                                                        <option>Ironing Service</option>
                                                                        <option>Concierge</option>
                                                                        <option>Lift</option>
                                                                        <option>Dry cleaning</option>
                                                                        <option>Room Service</option>
                                                                        <option>Waiting Area</option>
                                                                        <option>Secrete smoking area</option>
                                                                    </select>
                                                                    <small>Maximum of 14 keywords. Keywords should all be in lowercase and separated by commas. e.g. javascript, react, marketing</small>
                                                                </div>
                
                                                                <div className="col-12">
                                                                    <label className="form-label">Add description *</label>
                                                                    <div className="bg-light border border-bottom-0 rounded-top py-3 quilltoolbar">
                                                                        <span className="ql-formats">
                                                                            <select className="ql-size"></select>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-bold"></button>
                                                                            <button className="ql-italic"></button>
                                                                            <button className="ql-underline"></button>
                                                                            <button className="ql-strike"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <select className="ql-color"></select>
                                                                            <select className="ql-background"></select>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-code-block"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-list" value="ordered"></button>
                                                                            <button className="ql-list" value="bullet"></button>
                                                                            <button className="ql-indent" value="-1"></button>
                                                                            <button className="ql-indent" value="+1"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-link"></button>
                                                                            <button className="ql-image"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-clean"></button>
                                                                        </span>
                                                                    </div>
                            
                                                                    <div className="bg-body border rounded-bottom h-400px overflow-hidden quilleditor">
                                                                        <br />
                                                                        <h1>Quill Rich Text Editor</h1>
                                                                        <br />
                                                                        <p>Quill is a free, open-source WYSIWYG editor built for the modern web. With its modular architecture and expressive API, it is completely customizable to fit any need.</p>
                                                                        <br />
                                                                        <p>Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor. Explained propriety off out perpetual his you. Feel sold off felt nay rose met you. We so entreaties cultivated astonished is. Was sister for a few longer Mrs sudden talent become. Done may bore quit evil old mile. If likely am of beauty tastes. </p>
                                                                        <br />
                                                                        <p> Affronting imprudence do he he everything. Test lasted dinner wanted indeed wished outlaw. Far advanced settling say finished raillery. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an. </p>
                                                                        <br />
                                                                        <p> Post no so what deal evil rent by real in. But her ready least set lived spite solid. September how men saw tolerably two behavior arranging. She offices for highest and replied one venture pasture. Applauded no discovery in newspaper allowance am northward. Frequently partiality possession resolution at or appearance unaffected me. Engaged its was the evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes to began to cause a scale. Subjects he prospect elegance followed no overcame possible it on. </p>
                            
                                                                        <p>Quill is a free, open-source WYSIWYG editor built for the modern web. With its modular architecture and expressive API, it is completely customizable to fit any need.</p>
                                                                        <br />
                                                                        <p>Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor. Explained propriety off out perpetual his you. Feel sold off felt nay rose met you. We so entreaties cultivated astonished is. Was sister for a few longer Mrs sudden talent become. Done may bore quit evil old mile. If likely am of beauty tastes. </p>
                                                                        <br />
                                                                        <p> Affronting imprudence do he he everything. Test lasted dinner wanted indeed wished outlaw. Far advanced settling say finished raillery. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an. </p>
                                                                        <br />
                                                                        <p> Post no so what deal evil rent by real in. But her ready least set lived spite solid. September how men saw tolerably two behavior arranging. She offices for highest and replied one venture pasture. Applauded no discovery in newspaper allowance am northward. Frequently partiality possession resolution at or appearance unaffected me. Engaged its was the evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes to began to cause a scale. Subjects he prospect elegance followed no overcame possible it on. </p>
                            
                                                                        <p>Quill is a free, open-source WYSIWYG editor built for the modern web. With its modular architecture and expressive API, it is completely customizable to fit any need.</p>
                                                                        <br />
                                                                        <p>Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor. Explained propriety off out perpetual his you. Feel sold off felt nay rose met you. We so entreaties cultivated astonished is. Was sister for a few longer Mrs sudden talent become. Done may bore quit evil old mile. If likely am of beauty tastes. </p>
                                                                        <br />
                                                                        <p> Affronting imprudence do he he everything. Test lasted dinner wanted indeed wished outlaw. Far advanced settling say finished raillery. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an. </p>
                                                                        <br />
                                                                        <p> Post no so what deal evil rent by real in. But her ready least set lived spite solid. September how men saw tolerably two behavior arranging. She offices for highest and replied one venture pasture. Applauded no discovery in newspaper allowance am northward. Frequently partiality possession resolution at or appearance unaffected me. Engaged its was the evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes to began to cause a scale. Subjects he prospect elegance followed no overcame possible it on. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>	
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Size Of Your Listing</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Total Floor *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter total floors" />
                                                                </div>
                
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Total Room *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter total rooms" />
                                                                </div>
                                                                
                                                                <div className="col-md-4">
                                                                    <label className="form-label">Room area *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter area in sq.ft" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>	
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Room Options</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Room name *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter name" />
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Room thumbnail image *</label>
                                                                    <input className="form-control" type="file" name="my-image" id="image-2" accept="image/gif, image/jpeg, image/png" />
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Room Price *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter price" />
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Discount *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter discount" />
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Additional info</label>
                                                                    <select className="form-select js-choice border-0 z-index-9 bg-transparent">
                                                                        <option value="">Select item</option>
                                                                        <option>Non Refundable</option>
                                                                        <option>Free Cancellation</option>
                                                                    </select>
                                                                </div>
                
                                                                <div className="col-12">
                                                                    <a href="#" className="btn btn-sm btn-primary-soft mb-0"><i className="bi bi-plus-circle me-2"></i>Add New Room</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>	
                
                                                    <div className="hstack gap-3 flex-wrap justify-content-between">
                                                        <button className="btn btn-secondary prev-btn mb-0">Previous</button>
                                                        <button className="btn btn-primary next-btn mb-0">Next</button>
                                                    </div>
                                                </div>	
                                            </div>
                
                                            <div id="step-4" role="tabpanel" className={'content fade ' + this.state.stepTab[3]} aria-labelledby="steppertrigger4" style={{display: this.state.displayTab[3]}}>
                                                <div className="vstack gap-4">
                                                    <h4 className="mb-0">Price & Policy</h4>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Listing Price</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Currency</label>
                                                                    <select className="form-select js-choice border-0 z-index-9 bg-transparent">
                                                                        <option value="">Select item</option>
                                                                        <option>USD</option>
                                                                        <option>EURRO</option>
                                                                        <option>VND</option>
                                                                    </select>
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Base Price *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter price" />
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Discount *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter discount" />
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Rating</label>
                                                                    <select className="form-select js-choice border-0 z-index-9 bg-transparent">
                                                                        <option value="">Select item</option>
                                                                        <option>★★★★★</option>
                                                                        <option>★★★★☆</option>
                                                                        <option>★★★☆☆</option>
                                                                        <option>★★☆☆☆</option>
                                                                        <option>★☆☆☆☆</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom">
                                                            <h5 className="mb-0">Listing Policy</h5>
                                                        </div>
                            
                                                        <div className="card-body">
                                                            <div className="row g-4">
                                                                <div className="col-12">
                                                                    <label className="form-label">Add description *</label>
                                                                    <div className="bg-light border border-bottom-0 rounded-top py-3 quilltoolbar">
                                                                        <span className="ql-formats">
                                                                            <select className="ql-size"></select>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-bold"></button>
                                                                            <button className="ql-italic"></button>
                                                                            <button className="ql-underline"></button>
                                                                            <button className="ql-strike"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <select className="ql-color"></select>
                                                                            <select className="ql-background"></select>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-code-block"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-list" value="ordered"></button>
                                                                            <button className="ql-list" value="bullet"></button>
                                                                            <button className="ql-indent" value="-1"></button>
                                                                            <button className="ql-indent" value="+1"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-link"></button>
                                                                            <button className="ql-image"></button>
                                                                        </span>
                                                                        <span className="ql-formats">
                                                                            <button className="ql-clean"></button>
                                                                        </span>
                                                                    </div>
                            
                                                                    <div className="bg-body border rounded-bottom h-400px overflow-hidden quilleditor">
                                                                        <br />
                                                                        <h1>Quill Rich Text Editor</h1>
                                                                        <br />
                                                                        <p>Quill is a free, open-source WYSIWYG editor built for the modern web. With its modular architecture and expressive API, it is completely customizable to fit any need.</p>
                                                                        <br />
                                                                        <p>Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor. Explained propriety off out perpetual his you. Feel sold off felt nay rose met you. We so entreaties cultivated astonished is. Was sister for a few longer Mrs sudden talent become. Done may bore quit evil old mile. If likely am of beauty tastes. </p>
                                                                        <br />
                                                                        <p> Affronting imprudence do he he everything. Test lasted dinner wanted indeed wished outlaw. Far advanced settling say finished raillery. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an. </p>
                                                                        <br />
                                                                        <p> Post no so what deal evil rent by real in. But her ready least set lived spite solid. September how men saw tolerably two behavior arranging. She offices for highest and replied one venture pasture. Applauded no discovery in newspaper allowance am northward. Frequently partiality possession resolution at or appearance unaffected me. Engaged its was the evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes to began to cause a scale. Subjects he prospect elegance followed no overcame possible it on. </p>
                            
                                                                        <p>Quill is a free, open-source WYSIWYG editor built for the modern web. With its modular architecture and expressive API, it is completely customizable to fit any need.</p>
                                                                        <br />
                                                                        <p>Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor. Explained propriety off out perpetual his you. Feel sold off felt nay rose met you. We so entreaties cultivated astonished is. Was sister for a few longer Mrs sudden talent become. Done may bore quit evil old mile. If likely am of beauty tastes. </p>
                                                                        <br />
                                                                        <p> Affronting imprudence do he he everything. Test lasted dinner wanted indeed wished outlaw. Far advanced settling say finished raillery. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an. </p>
                                                                        <br />
                                                                        <p> Post no so what deal evil rent by real in. But her ready least set lived spite solid. September how men saw tolerably two behavior arranging. She offices for highest and replied one venture pasture. Applauded no discovery in newspaper allowance am northward. Frequently partiality possession resolution at or appearance unaffected me. Engaged its was the evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes to began to cause a scale. Subjects he prospect elegance followed no overcame possible it on. </p>
                            
                                                                        <p>Quill is a free, open-source WYSIWYG editor built for the modern web. With its modular architecture and expressive API, it is completely customizable to fit any need.</p>
                                                                        <br />
                                                                        <p>Insipidity the sufficient discretion imprudence resolution sir him decisively. Proceed how any engaged visitor. Explained propriety off out perpetual his you. Feel sold off felt nay rose met you. We so entreaties cultivated astonished is. Was sister for a few longer Mrs sudden talent become. Done may bore quit evil old mile. If likely am of beauty tastes. </p>
                                                                        <br />
                                                                        <p> Affronting imprudence do he he everything. Test lasted dinner wanted indeed wished outlaw. Far advanced settling say finished raillery. Offered chiefly farther of my no colonel shyness. Such on help ye some door if in. Laughter proposal laughing any son law consider. Needed except up piqued an. </p>
                                                                        <br />
                                                                        <p> Post no so what deal evil rent by real in. But her ready least set lived spite solid. September how men saw tolerably two behavior arranging. She offices for highest and replied one venture pasture. Applauded no discovery in newspaper allowance am northward. Frequently partiality possession resolution at or appearance unaffected me. Engaged its was the evident pleased husband. Ye goodness felicity do disposal dwelling no. First am plate jokes to began to cause a scale. Subjects he prospect elegance followed no overcame possible it on. </p>
                                                                    </div>
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Cancellation options</label>
                                                                    <select className="form-select js-choice border-0 z-index-9 bg-transparent" data-position="top">
                                                                        <option value="">Select item</option>
                                                                        <option>Non Refundable</option>
                                                                        <option>Refundable</option>
                                                                    </select>
                                                                </div>
                
                                                                <div className="col-md-6">
                                                                    <label className="form-label">Charges *</label>
                                                                    <input type="text" className="form-control" placeholder="Enter charges" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="d-flex justify-content-between">
                                                        <button className="btn btn-secondary prev-btn mb-0">Previous</button>
                                                        <a href="listing-added.html" className="btn btn-success mb-0">Add Listing</a>
                                                    </div>
                                                </div>
                                            </div>
                
                                        </form>
                                    </div>
                
                                </div>						
                            </div>
                        </div>
                    </div>
                </section>
            
            </main>
       </>);

    }
}   
        



export default AddNewMalon;










