import React from 'react';
import './catalogue.css';
import RoomCard1 from './RoomCard1';


class AvailableMellonot extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Availablerooms: [
          {malonId: 1,
          location: 'Netanya South Becah',
          roomId: '1',
          roomName: 'Royal Palace 2BR',
          size: 100,
          bedrooms: 2,
          balcony: 1,

          seaView: 1,
          TV: {brand: 'Sony', size: 75, netflix: 1, disney: 1, amazonPrime: 1},
          pricePerNight: 1599,
          totalPrice: 9999,
          photos: ['PrincePalaceHaderaBeach0001.jpg', 'PrincePalaceNetanyaBeach0001.jpg', 'PrincePalaceTelAviv0001.jpg']
        },
     
          {malonId: 1,
          location: 'Netanya South Becah',
          roomId: '2',
          roomName: 'Junior Suite 1BR',
          size: 50,
          bedrooms: 1,
          balcony: 0,

          seaView: 0,
          TV: {brand: 'LG', size: 50, netflix: 1, disney: 1, amazonPrime: 1},
          pricePerNight: 799,
          totalPrice: 4990,
          photos: ['PrincePalaceHaderaBeach0001.jpg', 'PrincePalaceNetanyaBeach0001.jpg', 'PrincePalaceTelAviv0001.jpg']
        },
     
        {malonId: 2,
        location: 'Hadera Becah',
        roomId: '3',
        roomName: 'Silver Palace 3 BR',
        size: 150,
        bedrooms: 3,
        balcony: 1,

        seaView: 1,
        TV: {brand: 'Samsung', size: 65, netflix: 1, disney: 1, amazonPrime: 1},
        pricePerNight: 1249,
        totalPrice: 7499,
        photos: ['PrincePalaceHaderaBeach0001.jpg', 'PrincePalaceNetanyaBeach0001.jpg', 'PrincePalaceTelAviv0001.jpg']
      }
        ]
    };
    
    }
 
  

  
    render() {
      const rooms1 = this.state.Availablerooms;
      return (
          <div>
            <section className="pt-0">
              <div className="container">
                <div className="searchresult">
                    We come to Israel <br/> 
                    With 2 Adults + 1 children <br/>
                    At dates: Sat 01 April 2023 - Sat 08 April 2023

                   
                </div>
                <div className="row g-4">
                {rooms1.map(room => (<RoomCard1 key={room.roomId} malonId = {room.maonId} location={room.location} roomName={room.roomName} photos={room.photos} />)
                        )}
                </div>
              </div>
            </section>
          </div>   
      
          
      );
    }
  }

  export default AvailableMellonot;
