import React from 'react';
//import { Datepicker } from '@mobiscroll/react';
import { Eventcalendar } from '@mobiscroll/react';

//import './App.css';

function Calander() {

  const view = React.useMemo(() => {
      return {
          timeline: {
              type: 'month'
          }
      };
  }, []);
  
  const myEvents = React.useMemo(() => {
      return [
         


        /*
        {
          start: '2022-09-02T00:00',
          end: '2022-09-05T00:00',
          title: 'Event 1',
          resource: 1
      }, {
          start: '2022-09-10T09:00',
          end: '2022-09-15T15:00',
          title: 'Event 2',
          resource: 3
      }, {
          start: '2022-09-12T00:00',
          end: '2022-09-14T00:00',
          title: 'Event 3',
          resource: 4
      }, {
          start: '2022-09-15T07:00',
          end: '2022-09-20T12:00',
          title: 'Event 4',
          resource: 5
      }, {
          start: '2022-09-03T00:00',
          end: '2022-09-10T00:00',
          title: 'Event 5',
          resource: 6
      }, {
          start: '2022-09-10T08:00',
          end: '2022-09-11T20:00',
          title: 'Event 6',
          resource: 7
      }, {
          start: '2022-09-22T00:00',
          end: '2022-09-28T00:00',
          title: 'Event 7',
          resource: 7
      }, {
          start: '2022-09-08T00:00',
          end: '2022-09-13T00:00',
          title: 'Event 8',
          resource: 15
      }, {
          start: '2022-09-25T00:00',
          end: '2022-09-27T00:00',
          title: 'Event 9',
          resource: 10
      }, {
          start: '2022-09-20T00:00',
          end: '2022-09-23T00:00',
          title: 'Event 10',
          resource: 12
      }
    */
    ]
  }, []);
  
  const myResources = React.useMemo(() => {
      return [{
        id: 'Net',
        name: 'Netanya Beach',
        children:[{
            id: 2,
            name: 'Junior Suite Deluxe',
            color: '#76e083'
        },{
            id: 1,
            name: 'Royal Palace 2BR',
            color: '#e20000'
        },  {
            id: 5,
            name: 'Penthouse Palace',
            color: '#76e083'
        
        }]
    }, {
        id: 'Had',
        name: 'Hadera Beach',
        children:[{ 
            id: 3,
            name: 'Silver Palace',
            color: '#4981d6'
        }, {
            id: 4,
            name: 'Gold Palace',
            color: '#e25dd2'
        }]
    }
    

      /*{
          id: 5,
          name: 'Resource E',
          color: '#1dab2f'
      }, {
          id: 6,
          name: 'Resource F',
          color: '#d6d145'
      }, {
          id: 7,
          name: 'Resource G',
          color: '#34c8e0'
      }, {
          id: 8,
          name: 'Resource H',
          color: '#9dde46'
      }, {
          id: 9,
          name: 'Resource I',
          color: '#166f6f'
      }, {
          id: 10,
          name: 'Resource J',
          color: '#f7961e'
      }, {
          id: 11,
          name: 'Resource K',
          color: '#34c8e0'
      }, {
          id: 12,
          name: 'Resource L',
          color: '#af0000'
      }, {
          id: 13,
          name: 'Resource M',
          color: '#446f1c'
      }, {
          id: 14,
          name: 'Resource N',
          color: '#073138'
      }, {
          id: 15,
          name: 'Resource O',
          color: '#4caf00'
      } */
    ]
  }, []);
  



  const myScheduleEvent = React.useCallback((args) => {
    const event = args.original;
    return  <div className="md-meal-planner-event">
                <div className="md-meal-planner-event-title" style={{color:'#000000'}}>{event.guest}. {event.country}</div>
                <div className="md-meal-planner-event-desc" style={{color:'#000000', fontWeight: 10}}>{event.dates}, {event.totalNights} nights </div>
                <div className="md-meal-planner-event-desc" style={{color:'#000000', fontWeight: 10}}>{event.guests} Notes: {event.notes} </div>
                <div className="reservation-ammount" style={{color:'#3340c7'}}>{event.amount} ₪</div>
            </div>
    }, []);

  return (
      <Eventcalendar
          theme="ios" 
          themeVariant="light"
          view={view}
          data={myEvents}
          resources={myResources}
          renderScheduleEventContent={myScheduleEvent}
          //renderScheduleEventContent={myScheduleEvent}
     />
  ); 
}

export default Calander;

