import React from 'react';
import './PropertyGalleryGrid.css';
//import PrintGallery from './PrintGallery'
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const imagesUrl = config.imagesUrl;




const PropertyGalleryGrid2 = ({ photos }) => {
  
  return (
    <div className="gallery">
      {photos.map((malonImage, index) => (
        <div key={index} className={`gallery__item gallery__item--${index}`}>
          <img                                 
             src={imagesUrl + malonImage.filePath + '/' + malonImage.fileName}
            alt={`Room ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default PropertyGalleryGrid2;