import React from 'react';
//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"
//import 'tiny-slider/dist/tiny-slider.css';
//import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import SelectRoomButton from './SelectRoomButton';
import './catalogue.css';
import config from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SmarketDateRange from '../../Components/SmarketDateRange';
import LanguageContext from '../../LanguageContext';



//const apiUrl = process.env.REACT_APP_TITLE;
const url = config.url;
const imagesUrl = config.imagesUrl;


class RoomCard1 extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        images:[
            {index: 1 , src: '/Images/PrincePalaceHaderaBeach0001.jpg', el:'img'},
            {index: 2 , src: '/Images/PrincePalaceNetanyaBeach0001.jpg', el:'img2'},
            {index: 3 , src: '/Images/PrincePalaceTelAviv0001.jpg', el:'img3'},
            {index: 4 , src: 'assets/images/category/hotel/4by3/01.jpg', el:'img4'},
        ],
        cardStyle: 'backgroundColor: aqua',
    };
    
    }

    scrollToElement(id) {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }


    onClickStar(){
    }

    onOverCard(event){

        this.setState({
            cardStyle: '1px solid deeppink'
        });
    }

    onOutCard(event){
        this.setState({
            cardStyle: ''
        });
    }

    settings = {
        lazyload: true,
        nav: false,
        mouseDrag: true
      };

      imgStyle = {

      };


  
      static contextType = LanguageContext;

      render() {
      
      const { translate } = this.context;


    
      if (this.props.availability==1 || this.props.nightPrice==0){
        return (
            <div className="col-md-4 col-xl-4">
            <div className="card shadow p-2 pb-0 h-100" style={{border: `${ this.state.cardStyle }`}} onMouseEnter={() => this.onOverCard()} onMouseLeave={() => this.onOutCard()}>
                <div className="position-absolute top-0 start-0 z-index-1 m-4">
                    {
                        this.props.discountRate>0 && (
                            <div className="badge bg-danger text-white">{this.props.discountRate}% Off</div>

                        )
                    }
                </div>
            
                {/*Require ro build function for render images */}
                <Carousel>
                    {this.props.photos && this.props.photos.map((photo) => (
                        <Carousel.Item key={photo.id}>
                            <img
                            className="d-block w-100"
                            src={imagesUrl + '/' + photo.filePath + '/' + photo.fileName}
                            alt=""
                            />
                            <Carousel.Caption>
                            <h3>{''}</h3>
                            <p>{''}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
    
    
                        ))}
        </Carousel>
                

                
                <div className="card-body px-0 pb-0">
                    {/*<!-- Rating and cart --> */}
                    <div className="d-flex justify-content-between mb-3">
                        <a href="rate.html" className="badge bg-dark text-white" style={{backgroundColor: '#7fc7ff !important'}}
                        ><i className="bi fa-fw bi-star-fill me-2 text-warning"></i>4.5</a>
                        <div style={{color:'#e32ecfdb'}}>
                            {this.props.location}
                        </div>
                        <a href="#" className="h6 mb-0 z-index-2"><i className="bi fa-fw bi-bookmark"></i></a>
                        
                    </div>
            {/*<!-- Title  --> */}
                    <h5 className="card-title"><a href={"roomdetails?room=" + this.props.roomId + '&checkin=' + this.props.checkIn + '&checkout=' + this.props.checkOut + '&adults=' + this.props.adults + '&children=' + this.props.children + '&pets=' + this.props.pets }>{this.props.roomName}</a></h5>
            {/*<!--  List--> */}
            <div>
                        
                        {this.props.roomFeatures && (
                        <table>
                            <tr style={{backgroundColor: '#f5f5f6'}}>
                                <th>Size </th>
                                <th>BR </th>
                                
                                {this.props.roomFeatures.kitchen && this.props.roomFeatures.kitchen.kitchen===1 && (
                                <th>Kitchen </th>
                                )}
                                {this.props.roomFeatures.kitchen && this.props.roomFeatures.kitchen.kitchen===2 && (
                                <th>kitchenette </th>
                                )}
                                 
                                <th>{
                                    this.props.roomFeatures.bathRooms !== undefined &&
                                    this.props.roomFeatures.bathRooms > 1 && (
                                    <>
                                    {this.props.roomFeatures.bathRooms + ' '}
                                    Bathrooms
                                    </>)}
                                    {this.props.roomFeatures.bathRooms==1 && (
                                        <>
                                        Bathroom
                                        </>
                                    )}
                                 </th>
                                 {this.props.roomFeatures.balcony>0 && (
                                <th>Balcony </th>
                                )}
                               
                            </tr>
                                <td style={{ borderLeft: 'solid 1px #f5f5f6'}}>{this.props.roomFeatures.size}  ㎡</td>
                                <td>{this.props.roomFeatures.bedRooms}
                                </td>

                                
                                {this.props.roomFeatures.kitchen && this.props.roomFeatures.kitchen.kitchen>0 && (
                                <td>
                                    
                                </td>
                                )}
                                <td> {this.props.roomFeatures && this.props.roomFeatures.bath>0 && (
                                        <>
                                        {this.props.roomFeatures.bath}x <i className="fa fa-bath" aria-hidden="true"></i>
                                      </>
                                    )}
                                    {this.props.roomFeatures && this.props.roomFeatures.shower>0 && (
                                        <>
                                        {this.props.roomFeatures.shower}x <i className="fa fa-shower" aria-hidden="true"></i>
                                      </>
                                    )}
                                </td>
                                {this.props.roomFeatures.balcony>0 && (
                                <td>{this.props.roomFeatures.balcony} m</td>
                                )}
                            <tr>

                            </tr>
                        </table>
                        )}
                    </div>
                    <ul className="nav nav-divider mb-2 mb-sm-3">
                        <li className="nav-item">Air Conditioning</li>
                        <li className="nav-item">Free Fast Wifi</li>
                        <li className="nav-item">TV & Netflix</li>
                        <li className="nav-item">Sea View</li>
                    </ul>
                </div>
            {/*<!-- Card body END--> */}
                {/*<!-- Card footer START --> */}
                <div className="card-footer pt-0">
                    {/*<!--  Price and Button--> */}
                    <div className="d-sm-flex justify-content-sm-between align-items-center">
                        
                        <SelectRoomButton 
                            roomId={this.props.roomId} 
                            nightPrice={this.props.nightPrice}
                            discountedNightPrice={this.props.discountedNightPrice}
                            checkIn={this.props.checkIn}
							checkOut={this.props.checkOut}
							adults={this.props.adults}
							children={this.props.children}
							pets={this.props.pets}
                            
                        />


                    </div>
                    {this.props.discountedNightPrice==0 && (
                        <div>
                            	
                            <button type="button" class="btn btn-info" style={{width: 100 + '%'}} onClick={this.props.openDatesDisplay}>
                            {translate('selectYourDates')}
                            </button>
                        </div>
                        )}   
                </div>
            </div>
            </div>
          );
      } else{
          if (this.props.availability==0){
              return(
                <div className="col-md-4 col-xl-4">
                <div className="card shadow p-2 pb-0 h-100">
                <div style={{width: 50 +'%',
    margin: 'auto',
    position: 'relative',
    top: 25 +'%',
    zIndex: 10}}>
                    <div className="badge bg-danger text-white" style={{width:100 + '%'}}>SOLD OUT</div>
                </div>
                              
                {this.props.photos.length > 0 && (
                                <img
                                className="d-block w-100"
                                src={imagesUrl + '/' + this.props.photos[0].filePath + '/' + this.props.photos[0].fileName}
                                alt=""
                                style={{filter: 'grayscale(100%)', position: 'relative',
                                top: -20 + 'px',
                                borderRadius: 10 + 'px'}}
                                />)}
 
                    <div className="card-body px-3 pb-0">
                        {/*<!-- Rating and cart --> */}
                        <div className="d-flex justify-content-between mb-3">
                            <a href="rate.html" className="badge bg-dark text-white" style={{backgroundColor: '#7fc7ff !important'}}
                            ><i className="bi fa-fw bi-star-fill me-2 text-warning"></i>4.5</a>
                            <div style={{color:'#e32ecfdb'}}>
                                {this.props.location}
                            </div>
                            <a href="#" className="h6 mb-0 z-index-2"><i className="bi fa-fw bi-bookmark"></i></a>
                            
                        </div>
                {/*<!-- Title  --> */}
                        <h5 className="card-title"><a href={"roomdetails?room=" + this.props.roomId + '&checkin=' + this.props.checkIn + '&checkout=' + this.props.checkOut + '&adults=' + this.props.adults + '&children=' + this.props.children + '&pets=' + this.props.pets } >{this.props.roomName}</a></h5>
                     
                {/*<!--  List--> */}
                        <ul className="nav nav-divider mb-2 mb-sm-3">
                            <li className="nav-item">Air Conditioning</li>
                            <li className="nav-item">Wifi</li>
                            <li className="nav-item">Kitchen</li>
                            <li className="nav-item">Pool</li>
                        </ul>
                    </div>
                {/*<!-- Card body END--> */}
                    {/*<!-- Card footer START --> */}
                    <div className="card-footer pt-0">
                        
                    </div>
                </div>
                </div>
              )
          }
      }
      
    }
  }

  export default RoomCard1;



