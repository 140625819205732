/*
const config = {
  apiUrl: "https://localhost:7087/api",
  // Other configuration variables
  imagesUrl :"https://princepalace.co.il/",
  url :"https://localhost:7087"
};
*/


const config = {
    apiUrl: "https://princepalace.co.il/api",
    imagesUrl :"https://princepalace.b-cdn.net/",
    url :"https://princepalace.co.il"
  };

/*
const config = {
  apiUrl: "https://localhost:7087/api",
  imagesUrl :"https://princepalace.b-cdn.net/",
  url :"https://localhost:7087"
};
*/
  
  
  export default config;


