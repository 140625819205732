import React from 'react';
import './BarSelections.css';

const maxGuests = 16;

class GuestsSelection extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        adults: 1,
        children: 0,
        pets: 0,
        selectedGuests: {
          adults: 1,
          children: 0,
          pets: 0,
          selectedGuestsTxt: '1 Guest'
        },
       guestBoardDisplay: 'none',

    };
    
    this.handleClickOutside = this.handleClickOutside.bind(this);
     this.handleIncreaseAdults = this.handleIncreaseAdults.bind(this);
     this.handleDecreaseAdults = this.handleDecreaseAdults.bind(this);
     this.handleIncreaseChildren = this.handleIncreaseChildren.bind(this);
     this.handleDecreaseChildren = this.handleDecreaseChildren.bind(this);
     this.handleIncreasePets = this.handleIncreasePets.bind(this);
     this.handleDecreasePets = this.handleDecreasePets.bind(this);
     this.handleGuestsBoardDisplay = this.handleGuestsBoardDisplay.bind(this);
     this.handleSelectedGuests = this.handleSelectedGuests.bind(this);
    }
 
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    handleSubmitGuests(){
      this.props.onSubmitNumberOfGuests(this.state.selectedGuests);
    }


    handleSelectedGuests(){
        let txt1 = 'Please select';
        if (this.state.adults > 0){
            txt1 = this.state.adults + ' Adults';
            if (this.state.children > 0){
                txt1 += ', ' + this.state.children + ' Kids';
            } 
            if (this.state.pets > 0){
                txt1 += ', ' + this.state.pets + 'Pets';
            }
        } else {
            if (this.state.children > 0){
                txt1 += this.state.children + ' Kids';
                if (this.state.pets > 0){
                    txt1 += ', ' + this.state.pets + ' Pets';
                }
            } 
        }
        let tempGuests = {};
        tempGuests.adults = this.state.adults;
        tempGuests.children = this.state.children;
        tempGuests.pets = this.state.pets;
        tempGuests.selectedGuestsTxt = txt1;
        this.setState({
          selectedGuests: tempGuests
        },() => { this.handleSubmitGuests(); })
    }
  
    handleIncreaseAdults() {
      let adults = this.state.adults;
      if (adults<maxGuests){
        //this.setState({adults: adults + 1});  
        this.setState({
          adults: adults + 1
        },() => { this.handleSelectedGuests(); })
      }
      }
    
    handleDecreaseAdults() {
        let adults = this.state.adults;
        if (adults>0){
          //this.setState({adults: adults - 1});
          if (adults<maxGuests){
            this.setState({
              adults: adults - 1
            },() => { this.handleSelectedGuests(); })
          }
        }
        
      }
      handleIncreaseChildren() {
      let children = this.state.children;
      if (children<maxGuests){
        //this.setState({children: children + 1});
        this.setState({
          children: children + 1
        },() => { this.handleSelectedGuests(); })
      }
        
    }
    handleDecreaseChildren() {
        let children = this.state.children;
        if (children>0){
          this.setState({
            children: children - 1
          },() => { this.handleSelectedGuests(); })
        }
      }
      handleIncreasePets() {
      let pets = this.state.pets;
      if (pets<maxGuests){
        this.setState({
          pets: pets + 1
        },() => { this.handleSelectedGuests(); })
      }

        
    }
    handleDecreasePets() {
        let pets = this.state.pets;
        if (pets>0){
          this.setState({
            pets: pets - 1
          },() => { this.handleSelectedGuests(); })
        }
      }
      /*
    handleSubmit(event) {
      alert('A name was submitted: ' + this.state.value);
      event.preventDefault();
    }
*/

    handleGuestsBoardDisplay(){
      if (this.state.guestBoardDisplay === 'none'){
        this.setState({guestBoardDisplay: 'block'});
      } else{
        this.setState({guestBoardDisplay: 'none'});
      }
    }
    
    handleClickOutside(event) {
      if (this.state.guestBoardDisplay === 'block'){
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.setState({guestBoardDisplay: 'none'});
        }
      }
    }
  
    render() {
      return (
          <div ref={this.wrapperRef}>
            <div className='selected' onClick={this.handleGuestsBoardDisplay}>
                {this.state.selectedGuests.selectedGuestsTxt}
            </div>
            <div className='guestBoard' style={{ backgroundColor: 'rgb(239, 240, 240)', display: this.state.guestBoardDisplay,  borderRadius: 20 + 'px', color:'#000'}} >
                <div id='adults' className='selectRow'>
                  <label className='guessLabel'  style={{width: 40 + '%', textAlign: 'left'}}>
                      Adults:
                      <div className="sublabel">Ages 16 or above</div>
                  </label>
                  <div style={{display:'flex', margin: 'auto'}}>
                  <button onClick={this.handleDecreaseAdults}> - </button>
                      <div style={{margin: 'auto', width:20+'px'}}>{this.state.adults} </div>
                      <button onClick={this.handleIncreaseAdults}> + </button>
                  </div>
               
                </div>
                <div id='children' className='selectRow'>
                  <label className='guessLabel' style={{width: 40 + '%', textAlign: 'left'}}>
                      Children:
                      <div className="sublabel">Ages 0-15</div>
                  </label>
                  <div style={{display:'flex', margin: 'auto'}}>
                      <div>
                        <button onClick={this.handleDecreaseChildren}> - </button>
                      </div>
                        <div style={{margin: 'auto', width:20+'px'}}>{this.state.children} </div>
                      <div>
                        <button onClick={this.handleIncreaseChildren}> + </button>
                      </div>
                  </div>
                  
                </div>
                <div id='cpets' className='selectRow'>
                  <label className='guessLabel'  style={{width: 40 + '%', textAlign: 'left'}}>
                      Pets:
                  </label>
                  <div style={{display:'flex', margin: 'auto'}}>
                      <div>
                        <button onClick={this.handleDecreasePets}> - </button>
                      </div>
                        <div style={{margin: 'auto', width:20+'px'}}>{this.state.pets} </div>
                      <div>
                        <button onClick={this.handleIncreasePets}> + </button>
                      </div>
                  </div>
                  
                </div>
                <button type="button" className="guestsContinue btn btn-info" onClick={()=>{this.setState({guestBoardDisplay: 'none'});}}> Continue </button>
            </div>
        
          </div>
      );
    }
  }

  export default GuestsSelection;
