import React from 'react';

class SelectCurrency extends React.Component {

    render() {

        return (
            <label>
                Currency:
                <select value={this.props.currency} onChange={(e) => this.props.onChange(e)} className="form-select" aria-label="Default select example">
                <option value="ILS"> ILS </option>
                <option value="USD"> USD </option>
                <option value="Euro"> Euro </option>
                <option value="GBP"> GBP </option>
                </select>                        
            </label>                          
        );
    }
}

export default SelectCurrency;
