import React from "react";
import Geocode from "react-geocode";
import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import NewMap from './map';


Geocode.setApiKey("AIzaSyCNd_uz6fds_4bXOzy0oqj3ZTFIMHvbASw");


function MapCreator(props) {
    const { isLoaded } = useLoadScript({
        //googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        googleMapsApiKey: "AIzaSyCNd_uz6fds_4bXOzy0oqj3ZTFIMHvbASw",
      });
      const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

    const [country, setCountry] = React.useState(props.country);
    const [city, setCity] = React.useState(props.city);
    const [street, setSteet] = React.useState(props.street);
    const [lat, setLat] = React.useState(31.413);
    const [lng, setLng] = React.useState(34.817);
    const [zoom, SetZoom] = React.useState(5);

    const [mapAddress, setMapAddress] = React.useState("");
    const [imgMapSrc, setImgMapSrc] = React.useState("");
  


    React.useEffect(() => {
        const address = props.street + ', ' + props.city + ', ' + props.country;
        Geocode.fromAddress(address).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              setLat(lat);
              setLng(lng);
              SetZoom(15);
            },
            (error) => {
              console.error(error);
            }
          );
      }, [props.street]);

        return (
            <>
                <div className="col-md-6">
                    <label className="form-label">Latitude *</label>
                    <input className="form-control" type="text" value={lat} readOnly={true}/>
                </div>

                <div className="col-md-6">
                    <label className="form-label">Longitude *</label>
                    <input className="form-control" type="text" value={lng} readOnly={true}/>
                </div>
                <div className="col-12">

                    <div id="map" className="w-100 h-300px grayscale rounded" height="500" style={{border:0}} ariaHidden="false" tabIndex="0">
                    
                    <NewMap class="w-100 h-300px grayscale rounded" lat={lat} lng={lng} zoom={zoom}/>

                    </div>
                </div>

            </>
            
        )
}

export default MapCreator;


