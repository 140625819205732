import React from "react";
import { useState, useEffect } from "react";
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Marker } from "react-mapbox-gl";

const NewMap = (props) => {
  
    const [imgSrc, setImgSrc]=  useState('https://maps.googleapis.com/maps/api/staticmap?center=32.2817177,34.8416128&amp;zoom=14&amp;size=900x300&amp;maptype=roadmap&&key=AIzaSyCNd_uz6fds_4bXOzy0oqj3ZTFIMHvbASw');
 
    const Map = ReactMapboxGl({
        accessToken:
          'pk.eyJ1Ijoic21hcmtldDEiLCJhIjoiY2xoNjFrYTQ0MDFuMDNibXQ1a2U1ejZjaSJ9.7rmjqoiiyZps3EZQAWQi7Q',
          lat: 31.413,
          lng: 34.817
      });

      const markerUrl = '../../../public/favicon1.png';

let zoomExtra = 0;
if (window.innerWidth<600){
    zoomExtra = 2;
}
    


  return (
  
     <Map
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
                height: '100%',
                width: '100%',
                borderRadius: 20 + 'px'
            }}
            center={[props.lng, props.lat]}
            zoom= {[props.zoom - zoomExtra]}
            >
            
            <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                <Feature coordinates={[props.lng, props.lat]} />
            </Layer>
            
    </Map>
  );
};

export default NewMap;