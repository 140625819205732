import React from 'react';
import './BarSelections.css';
import LanguageContext from '../../LanguageContext';

class LocationSelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        locationId: 0,
        selectedLocation: 'Israel',
       locationBoardDisplay: 'none'
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
     this.handleLocation = this.handleLocation.bind(this);
     this.handleLocationBoardDisplay = this.handleLocationBoardDisplay.bind(this);
    }
 
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    handleLocation(locationId, locationName){
      console.log(locationId);
        this.setState({locationId: locationId});
        this.setState({selectedLocation: locationName});
        this.props.onSelectLocation(locationId);
        this.setState({locationBoardDisplay: 'none'});

    }


    handleLocationBoardDisplay(){
      if (this.state.locationBoardDisplay === 'none'){
        this.setState({locationBoardDisplay: 'block'});
      } else{
        this.setState({locationBoardDisplay: 'none'});
      }
    }
    
    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.setState({locationBoardDisplay: 'none'});
      }
    }
    static contextType = LanguageContext;

    render() {

      const { translate } = this.context;
      return (
            <div ref={this.wrapperRef}>
                <div className='locationSelected' onClick={this.handleLocationBoardDisplay}>
                    {this.state.selectedLocation}
                </div>
                <div className='locationBoard' style={{ padding: 10 + 'px', zIndex:50, backgroundColor: 'rgb(239, 240, 240)', display: this.state.locationBoardDisplay,  borderRadius: 20 + 'px', color:'#fff'}} >
                    <div id='locations' className='selectRow locations'>
                          <button className="locationButton" onClick={() => this.handleLocation('0', translate('israel'))}>
                              <div> <img className="locationImage" src="/Images/LocationIcons/israel.jpg" alt="Israel" width="100%"/></div>
                              <div>{translate('israel')} </div>
                          </button>
                          <button className="locationButton" onClick={() => this.handleLocation(1, translate('telAvivCenter'))}>
                              <div><img className="locationImage" src="/Images/LocationIcons/TelAvivView1.jpg" alt="Tel Aviv Center"/></div>
                              <div> {translate('telAvivCenter')} </div>
                          </button>
                          <button className="locationButton" onClick={() => this.handleLocation(2, translate('netanyaSouthBeach'))}>
                              <div><img className="locationImage" src="/Images/LocationIcons/NetanyaBeachBuilding1.jpg" alt="Netanya beach"/></div>
                              <div> {translate('netanyaSouthBeach')} </div>
                          </button>
             
                          <button className="locationButton" onClick={() => this.handleLocation(3, translate('haderaBeach'))}>
                              <div><img className="locationImage" src="/Images/LocationIcons/HaderaBeachBuilding.jpg" alt="Hadera beach"/></div>
                              <div>{translate('haderaBeach')}</div>
                          </button>
                          <button className="locationButton" onClick={() => this.handleLocation(4, translate('herzeliaBeach'))}>
                              <div><img className="locationImage" src="/Images/LocationIcons/HerzeliaView1.jpg" alt="Herzelia beach"/></div>
                              <div> {translate('herzeliaBeach')} </div>
                          </button>
                    </div>
                    <div id='locations' className='selectRow locationsMobile'>
                          <div className='col-12'>
                          <button className="locationButton" onClick={() => this.handleLocation('0', translate('israel'))} style={{width: 90 + '%', marginLeft: 5+'%'}}>
                              <div> <img className="locationImageMain" src="/Images/LocationIcons/israel.jpg" alt="Israel" /></div>
                              <div> {translate('israel')} </div>
                          </button></div>
                          <button className="locationButton" onClick={() => this.handleLocation(1, translate('telAvivCenter'))} style={{width: 45 + '%'}}>
                              <div><img className="locationImage" src="/Images/LocationIcons/TelAvivView1.jpg" alt="Tel Aviv Center"/></div>
                              <div> {translate('telAvivCenter')} </div>
                          </button>
                          <button className="locationButton" onClick={() => this.handleLocation(2, translate('netanyaSouthBeach'))} style={{width: 45 + '%'}}>
                              <div><img className="locationImage" src="/Images/LocationIcons/NetanyaBeachBuilding1.jpg" alt="Netanya beach"/></div>
                              <div> {translate('netanyaSouthBeach')} </div>
                          </button>
             
                          <button className="locationButton" onClick={() => this.handleLocation(3, translate('haderaBeach'))} style={{width: 45 + '%'}}>
                              <div><img className="locationImage" src="/Images/LocationIcons/HaderaBeachBuilding.jpg" alt="Hadera beach"/></div>
                              <div>{translate('haderaBeach')} </div>
                          </button>
                          <button className="locationButton" onClick={() => this.handleLocation(4, translate('herzeliaBeach'))} style={{width: 45 + '%'}}>
                              <div><img className="locationImage" src="/Images/LocationIcons/HerzeliaView1.jpg" alt="Herzelia beach"/></div>
                              <div>{translate('herzeliaBeach')}</div>
                          </button>
                    </div>
                    
                </div>
        
          </div>
      );
    }
  }

  export default LocationSelect;