import React, { createContext, useContext, useState } from 'react';

import translations from './translations';

const supportedLanguages = ['en', 'he'];

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('he');


  const translate = (label) => {
    return translations[language][label];
  };

  const changeLanguage = (newLanguage) => {
    if (supportedLanguages.includes(newLanguage)) {
      setLanguage(newLanguage);
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, translate, supportedLanguages }}>

      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export default LanguageContext; // Export the LanguageContext
