import React from 'react';
import './catalogue.css';
import RoomCard1 from './RoomCard1';
//import axios from 'axios';




class AvailableRooms extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        rooms: this.props.rooms,
        malonName: this.props.malonName,                              
        Availablerooms: [
          {malonId: 1,
          location: 'Netanya South Becah',
          roomId: '1',
          roomName: 'Royal Palace 2BR',
          size: 100,
          bedrooms: 2,
          balcony: 1,

          seaView: 1,
          TV: {brand: 'Sony', size: 75, netflix: 1, disney: 1, amazonPrime: 1},
          pricePerNight: 1599,
          totalPrice: 9999,
          photos: ['PrincePalaceHaderaBeach0001.jpg', 'PrincePalaceNetanyaBeach0001.jpg', 'PrincePalaceTelAviv0001.jpg']
        },
     
          {malonId: 1,
          location: 'Netanya South Becah',
          roomId: '2',
          roomName: 'Junior Suite 1BR',
          size: 50,
          bedrooms: 1,
          balcony: 0,

          seaView: 0,
          TV: {brand: 'LG', size: 50, netflix: 1, disney: 1, amazonPrime: 1},
          pricePerNight: 799,
          totalPrice: 4990,
          photos: ['PrincePalaceHaderaBeach0001.jpg', 'PrincePalaceNetanyaBeach0001.jpg', 'PrincePalaceTelAviv0001.jpg']
        },
     
        {malonId: 2,
        location: 'Hadera Becah',
        roomId: '3',
        roomName: 'Silver Palace 3 BR',
        size: 150,
        bedrooms: 3,
        balcony: 1,

        seaView: 1,
        TV: {brand: 'Samsung', size: 65, netflix: 1, disney: 1, amazonPrime: 1},
        pricePerNight: 1249,
        totalPrice: 7499,
        photos: ['PrincePalaceHaderaBeach0001.jpg', 'PrincePalaceNetanyaBeach0001.jpg', 'PrincePalaceTelAviv0001.jpg']
      }
        ]
    };
    
    }
 
   
    render() {


        return (
          <div>
            <section className="pt-0">
              <div className="container" style={{maxWidth: 100 + '%', padding: 0}}>
                <div className="row g-4">
                 
                 
                {this.props.rooms && this.props.rooms.map((room) => (
                  <RoomCard1
                    key= {room.roomId}
                    roomId = {room.roomId}
                    malonId={1}
                    roomFeatures = {this.props.roomsFeatures && this.props.roomsFeatures.find(item => item.roomId == room.roomId)}

                    location={this.props.malonName}
                    availability={room.availability}
                    discountedNightPrice={room.roomPrice.avarageDiscountedNightPrice}                    
                    nightPrice={room.roomPrice.avarageNightPrice}
                    discountRate={room.roomPrice.totalDiscountRate}
                    netPrice={room.roomPrice.netPrice}
                    roomName={room.roomName}
                    photos={this.props.roomsPhotosGallery &&  this.props.roomsPhotosGallery.filter(photo => photo.roomId === room.roomId)}
                    checkIn={this.props.checkIn}
									  checkOut={this.props.checkOut}
									  adults={this.props.adults}
									  children={this.props.children}
									  pets={this.props.pets}
                    handleDates = {this.props.handleDates}
                    openDatesDisplay = {this.props.openDatesDisplay}

                  />
                    ))}               
                     </div>
              </div>
            </section>
          </div>   
      
          
      );
    } 
  }

  export default AvailableRooms;
