import React from 'react';

class PaymentItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let listItems = '';

       
        if (this.props.paymentLines.length > 0){
            listItems = this.props.paymentLines.map((item) =>
            
            <tr key={item.key}>
                <td>{item.payBy}</td>
                <td>{item.payment}</td>
                <td>{item.currency}</td>
                <td>{item.paymentMethod}</td>
                <td>{item.paymentDateValue}</td>
                <td>{item.paymentStatus}</td>
                <td>Edit</td>
            </tr>);
        }

        return (
            <>
                {listItems}    
            </>      
            

        );
    }


}

export default PaymentItems;


