import React, {useEffect} from 'react';
import { Datepicker } from '@mobiscroll/react';
import { Input } from '@mobiscroll/react';
import { formatDate, getJson, setOptions } from '@mobiscroll/react';


export default function SmarketDateRangePicker({onSelectDates, calanderPages, closeDatesDisplay, showGuestDiv}) {
  
//function SmarketDateRange() {
const startDate = new Date(); ///should be today
const endDate = new Date(startDate);
endDate.setDate(startDate.getDate() + 7);
    const [start, startRef] = React.useState(null);
    const [end, endRef] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState([startDate, endDate]);
    const [colors, setColors] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [invalid, setInvalid] = React.useState([]);
   // const [calanderPages, setCalanderPages] = React.useState([]);

    const now = new Date();


    const onDateChange = React.useCallback((ev) => {
        const date = ev.value;
        setSelectedDate(date);
        onSelectDates(date);

    }, []);  



    const getColors = React.useCallback((start, end) => {
        return [{
            date: start,
            cellCssClass: 'vacation-check-in'
        }, {
            date: end,
            cellCssClass: 'vacation-check-out'
        }, {
            start: new Date(start.getFullYear(), start.getMonth(), start.getDate() + 1),
            end: new Date(end.getFullYear(), end.getMonth(), end.getDate() - 1),
            background: '#ffbaba80',
            cellCssClass: 'vacation-booked'
        }];
    }, []);
    
        const SpecialDaysColors = [{
            background: '#87e4f3',
            start: '2023-04-25T18:00',
            end: '2023-04-26T19:00', 
        },
        {
            background: '#87e4f3',
            start: '2023-05-25T18:00',
            end: '2023-05-27T19:00', 
        },
        {
            background: '#ffbc1a',
            start: '2023-07-01T18:00',
            end: '2023-07-31T19:00', 
        }, 
        {
            background: '#ff1a5e',
        start: '2023-08-01T18:00',
        end: '2023-08-02T19:00',

        },{
            background: '#ffbc1a',
            start: '2023-08-03T18:00',
            end: '2023-08-31T19:00', 
        }, {
            background: '#87e4f3',
            start: '2023-09-15T18:00',
            end: '2023-09-17T19:00', 
        },
        {
            background: '#87e4f3',
            start: '2023-09-24T16:00',
            end: '2023-09-25T19:00', 
        },
        {
            background: '#87e4f3',
            start: '2023-09-29T16:00',
            end: '2023-10-06T19:00', 
        },  {
            background: '#87e4f3',
            start: '2023-12-07T16:00',
            end: '2023-12-15T19:00', 
        },
    
    
    ];

        const SpecialDayslabels = [{
                start: '2023-04-25T18:00',
                end: '2023-04-26T19:00',
                text: 'Israel Independence Day`',
                textColor: '#dd1bc6'
        },{
            start: '2023-05-25T18:00',
            end: '2023-05-27T19:00', 
            text: 'Shavuhot',
            textColor: '#dd1bc6'
        }, {
            start: '2023-07-01T18:00',
            end: '2023-07-31T19:00', 
            text: 'Happy Summer',
            textColor: '#dd1bc6'
        },
        {
            start: '2023-08-01T18:00',
            end: '2023-08-02T19:00', 
            text: 'Love Tu Behav',
            textColor: '#ffffff'
        }, {
            start: '2023-08-03T18:00',
            end: '2023-08-31T19:00', 
            text: 'Happy Summer',
            textColor: '#dd1bc6'
        }, {
            start: '2023-09-15T18:00',
            end: '2023-09-17T19:00', 
            text: 'Rosh Hashana',
            textColor: '#dd1bc6'
        }, {
            start: '2023-09-24T16:00',
            end: '2023-09-25T19:00', 
            text: 'Yom Kipur',
            textColor: '#dd1bc6'
        }, {
            start: '2023-09-29T16:00',
            end: '2023-10-06T19:00', 
            text: 'Sukkot',
            textColor: '#dd1bc6'
        }, {
            start: '2023-12-07T16:00',
            end: '2023-12-15T19:00', 
            text: 'Hanukka',
            textColor: '#dd1bc6'
        }
    ]
        
 

    
    return (
        
        <div>
         
            <Datepicker
                select="range"
                calendarType="month"
                pages={calanderPages}
                showWeekNumbers={false}
                calendarScroll="horizontal"
                showOuterDays={true}
                rangeHighlight={true}
                showOnClick={false}
                showOnFocus={false}



                //showRangeLabels={true}
                rangeStartLabel="Check-In"
                rangeEndLabel="Check-Out"
                dateFormat={"DD/MM/YYYY"}
                onChange={onDateChange}
                min={Date.now()}
                labels={SpecialDayslabels}
            invalid={invalid}
            colors={SpecialDaysColors}
          //  cssClass="md-book-rental"
            controls={['calendar']}
          //  display="inline"
            showRangeLabels={true}
            inRangeInvalid={true}
            rangeEndInvalid={true}
            display="inline"
            //renderCalendarHeader={calendarHeader}
            //labels={labels}

           // invalid={invalid}
            //colors={colors}
            />
   <div className="centerButton">
                   <button onClick={showGuestDiv} type="button" className="btn btn-outline-info" style={{ left: 'calc(50% - 40px)'}}>Select</button>

       </div>

        </div>

    );

}

//export default SmarketDateRange();
