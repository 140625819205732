import React from 'react';

class ReservationItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let listItems = '';

        if (this.props.reservationLines.length > 0){
            listItems = this.props.reservationLines.map((item) =>
            <tr id={item.key} key={item.key}>
                <td>{item.property}</td>
                <td>{item.checkIn.getDate() + '/' + (item.checkIn.getMonth()+1) + '/' + item.checkIn.getFullYear()}</td>
                <td>{item.checkOut.getDate() + '/' + (item.checkOut.getMonth()+1) + '/' + item.checkOut.getFullYear()}</td>
                <td>{Math.floor((item.checkOut-item.checkIn)/86400000)}</td>
                <td>{item.guestsTxt}</td>
                <td>{item.guestCost + item.currency}</td>
                <td>{item.netRevenue + item.currency}</td>
                <td>{item.payBy}</td>
                <td>Edit</td>
            </tr>);
        }
        /*
        const listItems = this.props.reservationLines.map((item) =>
                    <tr>
                        <td>Netanya - Royal Palace 2BR</td>
                        <td>01/04/2023</td>
                        <td>11/04/2023</td>
                        <td>10</td>
                        <td>2 Adults, 2 Children</td>
                        <td>20,000 ILS</td>
                        <td>16,000 ILS</td>
                        <td>Agent</td>
                        <td>Edit</td>
                    </tr>

        
        );*/
        return (
            <>
                {listItems}    
            </>      
            

        );
    }


}

export default ReservationItems;


