import React from 'react';
import Calander from './Calander';
import AddNewReservationManual from './Components/Admin/AddNewReservationManual'
//import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
//import { Datepicker } from '@mobiscroll/react';
import './App.css';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Pages/Home'
import AvailableMellonot from './Components/Catalogue/AvailableMellonot';
import Header from './Components/Header/Header';
import AddNewMalon from './Components/Malon/addNewMalon';
import Palace from './Components/Catalogue/Palace';
import RoomDetails from './Components/Catalogue/RoomDetails';
import UploadPhoto1 from './Components/Malon/UploadImages';
import UploadRoomPhotos from './Components/Malon/UploadRoomPhotos';
import TravelAccount from './Pages/TravelerAccount.js'
import BookingConfirmation from './Components/Booking/BookingConfirmation';
import NewReservation from './Components/Booking/NewReservation';
import { LanguageProvider } from './LanguageContext';
import LanguageSwitcher from './LanguageSwitcher';


//import JPMorgan from './Components/JPMorgan'
//import Mellonot from './Components/Catalogue/Mellonot';
function App(){

    return (
        <div>
        <LanguageProvider>
            <Header />
                        <LanguageSwitcher />



            <BrowserRouter>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/admin/newreservationmanual" element={<AddNewReservationManual />} />
                        <Route exact path="/admin/calander" element={<Calander />} />
                        <Route exact path="/catalogue" element={<AvailableMellonot />} />
                        <Route exact path="/addnewmalon/" element={<AddNewMalon />} />
                        <Route exact path="/Netanya-South-Beach/" element={<Palace />} />
                        <Route exact path="/Hadera-White-Beach/" element={<Palace />} />
                        <Route exact path="/Tel-Aviv-Center/" element={<Palace />} />
                        <Route exact path="/Herzelia-Beach/" element={<Palace />} />

                        <Route exact path="/Israel/" element={<Palace />} />


                        <Route exact path="/roomDetails/" element={<RoomDetails />} />

                        <Route exact path="/uploadroomphotos/" element={<UploadRoomPhotos />} />
                        <Route exact path="/account/" element={<TravelAccount />} />
                        <Route exact path="/confirmation/" element={<BookingConfirmation />} />
                        <Route exact path="/booking/" element={<NewReservation />} />

                    </Routes>
                </BrowserRouter>
            </LanguageProvider>

        </div>
  
       
    );  
}

export default App;
