//translation file

const translations = {
    en: {
        location: 'Location',
        date: 'Dates',
        guests: 'Guests',
        search: 'Search my vacation',
        pleaseSelect: 'Please select',
        luxuryApartmentsInIsrael: 'Luxury Apartments in Israel',
        primeLocation: 'Prime Locations from 1 day to 1 year',
        israel: 'Israel',
        netanyaSouthBeach: 'Netanya South Beach',
        telAvivCenter: 'Tel Aviv City Center',
        haderaBeach: 'Hadera Beach',
        herzeliaBeach: 'Herzelia Beach',
        startingAt: 'Starting at',
        night: 'Night',
        startFrom: 'Start from',
        viewOnMap: 'View On Map',
        checkIn: 'Check-In',
        checkOut: 'Check-Out',
        selectYourDates: 'Select Your Dates',
        selectYourPalace: 'Select Your Palace',
        selectThisPalace: 'Select This Palace',
        select: 'Select',
        size: 'Size',
        br: 'BR',
        kitchen: 'Kitchen',
        bathrooms: 'Bathrooms',
        bathroom: 'Bathroom',
        balcony: 'Balcony',
        adults: 'Adults',
        age16orAbove: 'Age 16 or above',
        children: 'Children',
        age0to15: 'Age 0-15',
        pets: 'Pets',
        continue: 'Continue'
    },
    he: {
        location: 'מיקום',
        date: 'תאריכים',
        guests: 'אורחים',
        search: 'חפש את החופשה שלי',
        pleaseSelect: 'בחר בבקשה',
        luxuryApartmentsInIsrael: 'דירות יוקרה בישראל',
        primeLocation: 'פריים לוקיישן מיום אחד עד שנה',
        israel: 'ישראל',
        netanyaSouthBeach: 'חוף נתניה',
        telAvivCenter: 'מרכז תל אביב',
        haderaBeach: 'חוף חדרה',
        herzeliaBeach: 'חוף הרצליה',
        startingAt: 'החל מ',
        night: 'לילה',
        startFrom: 'החל מ',
        viewOnMap: 'הצג על המפה',
        checkIn: 'צק אין',
        checkOut: 'צק האוט',
        selectYourDates: 'בחר את התאריכים שלך',
        selectYourPalace: 'בחר את הארמון שלך',
        selectThisPalace: 'בחר בארמון זה',
        select: 'בחר',
        size: 'גודל',
        br: 'חדרי שינה',
        kitchen: 'מטבח',
        bathrooms: 'חדרי רחצה',
        bathroom: 'חדר רחצה',
        balcony: 'מרפסת',
        adults: 'מבוגרים',
        age16orAbove: 'גיל 16 ומעלה',
        children: 'ילדים',
        age0to15: 'גיל 15-0',
        pets: 'חיות מחמד',
        continue: 'המשך'
    }
};

export default translations;
