import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import AvailableRooms from "./AvailableRooms";
import axios from 'axios';
import Gallery from "./Gallery";
import NewMap from "../Malon/map";
import './catalogue.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckInOutBox from './CheckInOutBox';
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const url = config.url;
const imagesUrl = config.imagesUrl;
const apiUrl = config.apiUrl;




const RoomDetails = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
	const [roomData, setRoomData] = useState({});
	const [roomDescription, setRoomDescription] = useState('');
    const [roomPrice, setRoomPrice] = useState({});


	const [roomImages, setRoomImages] = useState([]);
    //const [checkIn, setCheckIn] = useState('');
    let roomId = searchParams.get('room');
    let checkIn = searchParams.get('checkin');
    //setCheckIn(searchParams.get('checkin'));
    let checkOut = searchParams.get('checkout');
    let adults = searchParams.get('adults');
	if (!adults || adults === "0") {
		adults = 1;
	  }
    let children = searchParams.get('children');
	if (!children || children === "0") {
		children = 0;
	  }
    let pets = searchParams.get('pets');
	if (!pets || pets === "0") {
		pets = 0;
	  }



      useEffect(() => {
        //get Room data:
        const fetchRoomData = async () => {
            const result = await axios.get(
                apiUrl + '/catalogue/GetRoomData', {params: {
                        roomId: roomId, 
                }
            }
            ).then(result => {
                setRoomData(result.data);
  
            })


        };
        fetchRoomData();
    //get Available rooms data:
    const fetchData = async () => {
      const result = await axios.get(
             apiUrl + '/catalogue/GetRoomPrice', {params: {
                    roomId: roomId, 
                    checkIn: checkIn,
                    checkOut: checkOut,
                    adults: adults, 
                    children: children,
                    pets: pets,
                    rooms: 1
          }
        }

      ).then(result => {
        setRoomPrice(result.data);



      });
    };
    fetchData();
}, []);



  
const saveReservationLineToLocalStorage = (reservationLine) => {
    return new Promise((resolve, reject) => {
      try {
        // Save reservation line to local storage
        localStorage.setItem('reservationLine', JSON.stringify(reservationLine));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

const bookThisProperty = () => {
    
    let reservationLine = {};
    reservationLine.checkIn = checkIn;
    reservationLine.checkOut = checkOut;
    reservationLine.roomId = roomId;
    reservationLine.adults = adults;
    reservationLine.children = children;
    reservationLine.pets = pets;
      saveReservationLineToLocalStorage(reservationLine)
      .then(() => {
        window.location.href = '/booking'; // Redirect after saving
      })
      .catch((error) => {
        console.error('Error saving reservation line:', error);
      });
      


  
  }
 
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];



    return(
        <main style={{overflowX: 'hidden'}}>
            {/* Room photos galery start */}
            <section className="card-grid pt-0" style={{paddingBottom: 1 + 'rem'}}>
                <div class="container">
                    <Gallery propertyImages={roomData.photosGalery} />
                    <div style={{position: 'relative',
                    fontSize: 25 +'px',
                    color: '#df1694',
                    bottom: 45 + 'px',
                    margin: 5 + 'px',
                    float: 'right',
                    right: 10 + 'px'}}>
                        Start from $199/night
                    </div>
                </div>
            </section>

            {/* Room Details start*/}
            <section className="pt-0">
	<div className="container">
		<div className="row" style={{display: 'flex', width:100 + '%'}}>
            <div className="col-md-7 col-12  room-details">
                <div>
                    <div class="col-12" style={{marginBottom: 1 + 'px'}} >
                        <div class="d-lg-flex justify-content-lg-between mb-1" >
                            <div >
                                <div class="mb-2 mb-lg-0">
                                    <h2 class="fs-20" style={{fontSize: 20 + 'px'}}>{roomData.malonName}</h2>
                                    <h1 class="fs-30" style={{fontSize: 30 + 'px'}}>{roomData.roomName} </h1>
                                </div>
                            </div>
                            

                            <ul class="list-inline text-end">
                                <li class="list-inline-item">
                                    <a href="#" class="btn btn-sm btn-light px-2"><i class="fa-solid fa-fw fa-heart"></i></a>
                                </li>
                                <li class="list-inline-item dropdown">
                                    <a href="#" class="btn btn-sm btn-light px-2" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa-solid fa-fw fa-share-alt"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
                                        <li><a class="dropdown-item" href="#"><i class="fab fa-twitter-square me-2"></i>Twitter</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="fab fa-facebook-square me-2"></i>Facebook</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="fab fa-linkedin me-2"></i>LinkedIn</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="fa-solid fa-copy me-2"></i>Copy link</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <table>
                            <tr style={{backgroundColor: '#f5f5f6'}}>
                                <th>Size </th>
                                <th>Bedrooms </th>
                                {roomData.balconies>0 && (
                                <th>Balcony </th>
                                )}
                                {roomData.kitchen===1 && (
                                <th>Kitchen </th>
                                )}
                                {roomData.kitchen===2 && (
                                <th>kitchenette </th>
                                )}
                                 
                                <th>{roomData.bathrooms>1 && (
                                    <>
                                    {roomData.bathrooms + ' '}
                                    Bathrooms
                                    </>)}
                                    {roomData.bathrooms==1 && (
                                        <>
                                        Bathroom
                                        </>
                                    )}
                                 </th>
                                 
                                <th>Toilets</th>
                            </tr>
                                <td style={{ borderLeft: 'solid 1px #f5f5f6'}}>{roomData.roomSize}  ㎡</td>
                                <td>{roomData.bedrooms} x <i class="fa-solid fa-door-open"></i>, {roomData.beds} x <i class="fa-solid fa-bed"></i>
                                </td>

                                {roomData.balconies>0 && (
                                <td>{roomData.balconySize} m</td>
                                )}
                                {roomData.kitchen>0 && (
                                <td>
                                    <FontAwesomeIcon icon="fa-solid fa-refrigerator" />
                                    <i class="fa-thin fa-oven"></i>
                                    <i class="fa-solid fa-refrigerator"></i>
                                </td>
                                )}
                                <td> {roomData.roomFeatures && roomData.roomFeatures.bath>0 && (
                                        <>
                                        {roomData.roomFeatures.bath}x <i className="fa fa-bath" aria-hidden="true"></i>
                                      </>
                                    )}
                                    {roomData.roomFeatures && roomData.roomFeatures.shower>0 && (
                                        <>
                                        {roomData.roomFeatures.shower}x <i className="fa fa-shower" aria-hidden="true"></i>
                                      </>
                                    )}

                                </td>
                                <td style={{ borderRight: 'solid 1px #f5f5f6'}}> {roomData.toilets}</td>
                            <tr>

                            </tr>
                        </table>
                        
                    </div>
                    <div>
                        <div className="rooFeaturesIconContainer">
                            <div className="roomFeaturesIcon">
                                <img src="/Images/Room_Icons/Sony_LCD.png" alt="SONY 75 LCD"/>
                                <div className="roomFeatureIconLabel">SonyLCD 75"</div>
                            </div>
                            <div className="roomFeaturesIcon">
                                <img src="/Images/Room_Icons/Netflix2.png" alt="Netflix"/>
                                <div className="roomFeatureIconLabel">Netflix</div>
                            </div>
                            <div className="roomFeaturesIcon">
                                <img src="/Images/Room_Icons/playstation.png" alt="Netflix"/>
                                <div className="roomFeatureIconLabel">PlayStation</div>
                            </div>
                            <div className="roomFeaturesIcon">
                                <img src="/Images/Room_Icons/hi_speed_wifi.png" alt="Super Hi-Speed Wifi"/>
                            </div>
                            <div className="roomFeaturesIcon">
                                <img src="/Images/Room_Icons/Air_conditioner.png" alt="Air conditioner"/>
                                <div className="roomFeatureIconLabel">Air conditioner</div>
                            </div>
                            <div className="roomFeaturesIcon">
                                <img src="/Images/Room_Icons/sea_view.png" alt="Air conditioner"/>
                                <div className="roomFeatureIconLabel">Sea view</div>
                            </div>
                        </div>
                        
                        TV: Sony 75", Netflix, Super Fast Wi-Fi, Air conditioner
                    </div><br/>
                    <div>
                        <h5>Room desription</h5>
                        {roomData.description}

                    </div>
                    <div>
                        More Room details: <br/>
                        Full equiped kitchen
                        Double size refregiator
                        panoramic windows
                        premium bes
                        pillow menue
                        suround sound system
                        video games playstation
                        games for children: Lego
                        shower
                        Free comfort towels, 
                        premium bedlines
                        shampoo, conditioner, SABON
                        
                    </div>
                </div>
            </div>
            <div className="col-md-5 col-12  order-summary">
            <div class="card bg-transparent border">
					<div class="card-header bg-transparent border-bottom">
						<h4 class="card-title mb-0">Price Summary</h4>
					</div>
			
					<div class="card-body">

						<div class="row g-4 mb-3">
							<div class="col-md-6">
								<div class="bg-light py-3 px-4 rounded-3">
                                    <CheckInOutBox date={checkIn} check={'In'}/>
								</div>
							</div>

							<div class="col-md-6">
								<div class="bg-light py-3 px-4 rounded-3">
								    <CheckInOutBox date={checkOut} check={'Out'}/>
								</div>
							</div>
						</div>
                        <div class="col-md-12">
								<div class="bg-light py-3 px-4 rounded-3">
                                    <h6 style={{fontFamily: 'sans-serif'}}> 
								    {parseInt(adults) + parseInt(children)+parseInt(pets)} Guests: {adults} adults {children>0 && (', ' + children + ' children')}{pets>0 && (', ' + pets + ' pets')} 
                                    </h6>
                                </div>
							</div>
                        {roomPrice && roomPrice.totalPrice  ? (                    
						<ul class="list-group list-group-borderless mb-3">
							<li class="list-group-item px-2 d-flex justify-content-between">
								<span class="h6 fw-light mb-0">{roomPrice.avarageNightPrice} ILS x {roomPrice.totalNights} Nights</span>
								<span class="h6 fw-light mb-0">{roomPrice.totalPrice} ILS</span>
							</li>
                            {roomPrice.discount.map(discount => (
                                <li class="list-group-item px-2 d-flex justify-content-between">
                                    <span class="h6 fw-light mb-0">{discount.rate}% {discount.desctiption}</span>
                                    <span class="h6 fw-light mb-0">- ₪ {discount.value} ILS</span>
                                </li>
                            ))}

							
							<li class="list-group-item px-2 d-flex justify-content-between">
								<span class="h6 fw-light mb-0">Cleaning Fee</span>
								<span class="h6 fw-light mb-0">{roomPrice.cleaningFee} ILS</span>
							</li>
							<li class="list-group-item bg-light d-flex justify-content-between rounded-2 px-2 mt-2">
								<span class="h5 fw-normal mb-0 ps-1">Total</span>
								<span class="h5 fw-normal mb-0">{roomPrice.netPrice} ILS</span>
							</li>
						</ul>
                        ): (
                            <div>Loading data...</div>
                          )}
						<div class="d-grid gap-2">
							<button class="btn btn-dark mb-0" onClick={bookThisProperty}>Book this apartment</button>
						</div>
					</div>
				</div>
            
            
            </div>
			
		</div>
	</div>
</section>


        </main>
    );
  }





export default RoomDetails;











