import React from 'react';
import axios from 'axios';
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const url = config.url;
const apiUrl = config.apiUrl;


class UploadPhoto1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: [],
          malonId: this.props.malonId,
          roomId: this.props.roomId,
        }
         // this.handleSelect = this.handleSelect.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFileInputChange = this.handleFileInputChange.bind(this);
      }
      async handleFormSubmit(event) {
          let files = this.state.selectedFiles;
        event.preventDefault();
        if (this.state.selectedFiles.length === 0){
            return;
        }
        let formData = new FormData();
        /*
        for (const file of this.state.selectedFiles){
            formData.append("photos", file);
        }*/
        formData.append('malonId', this.props.malonId);
        formData.append('roomId', this.props.roomId); 
        for (let i=0; i< files.length; i++){
            formData.append('photos', files[i]);

        }


  
        await axios({

            method: "post",
            url: apiUrl + "/malon/UploadPhotos",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
     
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.log(error.response.data);
        });


      }
      handleFileInputChange(event){
          console.log(Array.from(event.target.files));
        this.setState({selectedFiles: Array.from(event.target.files)});
      }
      render(){
        return(
            <div>
               <div className="container">
                    <form onSubmit={this.handleFormSubmit} encType="multipart/form-data">
                        <div className="col-12">
                            <label className="form-label">Upload thumbnail image *</label>
                            <input 
                                className="form-control" 
                                type="file" 
                                name="malonImage" 
                                id="image-input" 
                                accept="image/gif, image/jpeg, image/png"
                                multiple
                                onChange={this.handleFileInputChange}
                                />
                            <p className="small mb-0 mt-2"><b>Note:</b> Only JPG, JPEG, and PNG. Our suggested dimensions are 600px * 450px. The larger image will be cropped to 4:3 to fit our thumbnails/previews.</p>

                        </div>
                        <div>
                            <button type="submit">Upload Photo</button>
                        </div>
                    </form>
                </div>
            </div>

     );}


}

export default UploadPhoto1;

