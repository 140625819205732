import React from 'react';
import axios from 'axios';
import UploadPhoto1 from './UploadPhoto1';


class UploadRoomPhotos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          malonId: 0,
          roomId: 0,
          mellonot:[{id: 1, name: 'Prince Palace Netanya Beach'},
                    {id:8, name: 'Prince Palace Hadera Beach'},
                    {id: 9, name: 'Prince Palace Tel Aviv Center'},
                    {id: 11, name: 'Prince Palace Herzliya Beach'}
                    ],

          rooms:[{malonId: 1, roomId: 1, name: 'Royal Palace 2BR'},
                 {malonId: 1, roomId: 2, name: 'Deluxe Palace 2BR'},
                 {malonId: 1, roomId: 3, name: 'Junior Suite platinium'},
                 {malonId: 1, roomId: 4, name: 'Junior Suite Gold'},
                 {malonId: 1, roomId: 5, name: 'Penthouse Palace'},
                 {malonId: 1, roomId: 6, name: 'Executive Suite'},
                 {malonId: 8, roomId: 7, name: 'Silver Penthouse Palace 3BR'},
                 {malonId: 8, roomId: 8, name: 'Gold Penthouse Palace 3BR'},
                 {malonId: 9, roomId: 9, name: 'Presidential Penthouse 2BR'},
                 {malonId: 9, roomId: 10, name: 'Exclusive Penthouse 1BR'},  
                 {malonId: 9, roomId: 11, name: 'Executive Penthouse 1BR'},  
                 {malonId: 11, roomId: 12, name: 'Delluxe Suite'},
                 {malonId: 8, roomId: 14, name: 'Garden Palace 2BR'}  
        ],
        filteredRooms: []
        }
          this.handleMalonSelect = this.handleMalonSelect.bind(this);
       // this.handleFormSubmit = this.handleFormSubmit.bind(this);
       // this.handleFileInputChange = this.handleFileInputChange.bind(this);
      }

      SelectItems = () => 
      {
        this.state.mellonot.map( (malon) => 
          <option key={malon.id}>{malon.name}</option> )
      };
      handleMalonSelect(event){
        this.setState({malonId: event.target.value}); 
        let filteredRooms = this.state.rooms.filter(room => room.malonId == event.target.value);
        this.setState({filteredRooms: filteredRooms});    
      }

      handleFileInputChange(event){
      this.setState({selectedFiles: Array.from(event.target.files)});
    }

      render(){

        //let filteresRooms = this.state.rooms.filter(room => this.state.rooms.malonId === this.state.malonId )

        /*
        SelectItem = function(X) {
            return <option value={X.id}>{X.name}</option>;
        };
*/

          return (<div>
                    <div>malon Id: {this.state.malonId}</div>
                    <div style={{width: 80 + '%', margin: 'auto'}}>
                        <div className="col">
                            <div className="mb-3">
                                <label htmlFor="malonId" className="form-label">Malon</label>
                                <select value={this.state.malonId} onChange={(e)=>this.handleMalonSelect(e)} className="form-select" aria-label="Default select example" name="malonId">
                                    <option value="0"> Select Hotel</option>
                                {this.state.mellonot.map(malon=><option key={malon.id} value={malon.id}>{malon.name}</option>)}
                                </select>                           
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-3">
                                <label htmlFor="Country" className="form-label">Apartment / Room</label>
                            <select value={this.state.roomId} onChange={(e) => this.setState({roomId: e.target.value})} className="form-select" aria-label="Default select example" name="roomId">
                            <option value="0"> Main Property photos and share area</option>
                                {this.state.filteredRooms.map(room=>(<option key={room.roomId} value={room.roomId}>{room.name}</option>))}
                              
                            </select>
                            </div>
                        </div>
                     </div>
          <UploadPhoto1 malonId={this.state.malonId} roomId={this.state.roomId} />
          </div>);
      }


    }

    export default UploadRoomPhotos;
