import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import AvailableRooms from "./AvailableRooms";
import axios from 'axios';
import Gallery from "./Gallery";
import NewMap from "../Malon/map"
import SmarketDateRange from '../../Components/SmarketDateRange';
import SmarketDateRangePicker from '../../Components/SmarketDateRangePicker';
import config from '../../config';
import './palace.css';
import GuestsBoard from "../SearchBar/GuestBoard";
import { useLanguage } from '../../LanguageContext';



//const apiUrl = process.env.REACT_APP_TITLE;
const url = config.url;
const apiUrl = config.apiUrl;
//const baseApiUrl = process.env.React_App_API;

const inputClick = function() {
	console.log("chjckhsjkchskjhc");
}
const handleInputClick = () => {
    console.log("chjckhsjkchskjhc");
  };

 

const SelectDatesBanner = (props) =>{
	const { translate } = useLanguage();

	if (props.checkIn==undefined || props.checkIn=='' || props.checkIn==null || props.checkIn=='null' || props.checkOut==null || props.checkOut=='null' || props.checkOut==undefined || props.checkOut=='' ){
		return (
			<div className="SelectYourDatesBanner" id="selectDates"
			style={{zIndex:21, width:100 + '%', top:160 + 'px', left: 0, padding: 5 + 'px', 
			 textAlign: 'center', fontSize: 20 + 'px', fontFamily:'sans-serif' }}
			>

<fieldset disabled>
	<div className="row">
		<div className="col-6 col-md-4 mb-3">
		<label for="checkIn">{translate('checkIn')}</label>
		<div className="checkInInput" onClick={props.openDatesDisplay}>{translate('selectYourDates')}
</div>
		</div>
		<div className="col-6 col-md-4 mb-3">
		<label for="checkOut">{translate('checkOut')}</label>
		<div className="checkInInput" onClick={props.openDatesDisplay}>{translate('selectYourDates')}</div>
		</div>
		<div className="col-md-4 mb-3">
		<label for="Guests">{translate('guests')}</label>
		<div className="checkInInput" id="Guests" onClick={props.showGuestDiv}>{props.adults + ' + ' + props.children + ' + ' + props.pets}</div>
		</div>
	</div>
</fieldset>


			
			</div>
		)
	} else{
		return(
			<>
			<fieldset disabled>
	<div className="row">
		<div className="col-6 col-md-4 mb-3">
		<label for="checkIn">{translate('checkIn')}</label>
		<div className="checkInInput" onClick={props.openDatesDisplay}>{props.checkIn}</div>

		</div>
		<div className="col-6 col-md-4 mb-3">
		<label for="checkOut">{translate('checkOut')}</label>
		<div className="checkInInput" onClick={props.openDatesDisplay}>{props.checkOut}</div>
		</div>
		<div className="col-md-4 mb-3">
		<label for="Guests">{translate('guests')}</label>
		<div className="checkInInput" id="Guests" onClick={props.showGuestDiv}>{props.adults + ' + ' + props.children + ' + ' + props.pets}</div>

		</div>
	</div>
</fieldset>
			
			</>
			
		)
	}

}





const Palace = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
	const [rooms, setRooms] = useState([]);
	const [malonData, setMalonData] = useState({});
	const [malonAddress, setMalonAddress] = useState('');
	const [malonCity, setMalonCity] = useState('');
	const [malonCountry, setMalonCountry] = useState('');
	const [malonDescription, setMalonDescription] = useState('');
	const [malonFullDescription, setMalonFullDescription] = useState('');
	const [malonDescriptionHe, setMalonDescriptionHe] = useState('');
	const [malonFullDescriptionHe, setMalonFullDescriptionHe] = useState('');

	const [roomsFeatures, setRoomsFeatures] = useState([]);
	const [openPicker, setOpenPicker] = useState(false);
	const [dateRangeDisplay, setDateRangeDisplay] = useState('none');

	const [malonImages, setMalonImages] = useState([]);
	const [googleX, setGoogleX]= useState(32);
	const [googleY, setGoogleY]= useState(34);
	const [googleZ, setGoogleZ]= useState(16);
	const [calanderPages, setCalanderPages]= useState(1);
	const [checkIn, setCheckIn] = useState(searchParams.get('checkin'));
	const [checkOut, setCheckOut] = useState(searchParams.get('checkout'));
	const [showSelect, setShowSelect] = useState(true);
	const [adults, setAdults] = useState(searchParams.get('adults'));
	const [children, setChildren] = useState(searchParams.get('children'));
	const [pets, setPets] = useState(searchParams.get('pets'));
	const [guestsTxt, setGuestsTxt] = useState('Select guests');
	const { translate } = useLanguage();
	const {language} =  useLanguage();

    let link = window.location.pathname;
	let malonName = '';
	let malonId = 0;
    const palace = link.split('/').slice(1).toString().toLowerCase();;
	
	switch (palace) {
		case 'netanya-south-beach':
			malonName = 'Prince Palace Netanya South Beach';
			malonId = 1;
			break;
		case 'hadera-white-beach':
			malonName = 'Prince Palace Hadera White Beach';
			malonId = 8;
			break;
		case 'tel-aviv-center':
			malonName = 'Prince Palace Tel Aviv center';
			malonId = 9;
			break;
		case 'herzliya-beach':
			malonName = 'Prince Palace Herzliya Beach';
			malonId = 11;
			break;
		default:
			malonName = 'Prince Palace';
			malonId = 0;
			break;				


	}

	const showDateDiv = () => {
		setDateRangeDisplay('flex');
		setShowSelect(true);
	};

	const showGuestDiv  = () => {
		setDateRangeDisplay('flex');
		setShowSelect(false);
	};

	const formatDate = (date) => {
        if (date === null) {
            // Handle the case when the date is null
            return '';
          }
        function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
          }
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear()
        ].join('/');
      }


	function handleGuest (data) {
            setAdults(data.adults);
            setChildren(data.children);
            setPets(data.pets);
            setGuestsTxt(data.selectedGuestsTxt);
       
    }  

   function handleDates (date) {

		  setCheckIn(formatDate(date[0]));
		  setCheckOut(formatDate(date[1]));
    }

	function closeDatesDisplay(date){
		setDateRangeDisplay('none');
	}
	function openDatesDisplay(date){
		setDateRangeDisplay('flex');
		showDateDiv();
	}

    
	useEffect(() => {
		const windowWidth = window.innerWidth;
        if (windowWidth> 600){
            setCalanderPages(2);
        }
		if (!adults || adults === "0") {
			setAdults(1);
		  }
		if (!children || children === "0") {
			setChildren(0);
		  }
	
		  if (!pets || pets === "0") {
			setPets(0);
		  }
	}, []);




	useEffect(() => {
		//get Malon data:
		const fetchMalonData = async () => {
			const result = await axios.get(
				   apiUrl + '/catalogue/GetMalonData', {params: {
						  malonId: malonId, 
				}
			  }
			).then(result => {
				setMalonImages(result.data.photosGalery);
				setMalonAddress(result.data.address);
				setMalonCity(result.data.city);
				setMalonCountry(result.data.country);
				setMalonDescription(result.data.description);
				setMalonFullDescription(result.data.fullDescription);
				setMalonDescriptionHe(result.data.descriptionHe);
				setMalonFullDescriptionHe(result.data.fullDescriptionHe);
				setGoogleX(result.data.google_X);
				setGoogleY(result.data.google_Y);
				setGoogleZ(result.data.google_Z);

				
				setMalonData(result.data);
				setRoomsFeatures(result.data.roomsFeatures);

			  })

			//setMalonData(result.data);
			//setMalonImages(result.data.photosGalery)
			
		  };
		  fetchMalonData();
		//get Available rooms data:
		const fetchData = async () => {
		  const result = await axios.get(
			     apiUrl + '/catalogue/GetAvailableRoomsInMalon', {params: {
						malonId: malonId, 
						checkIn: checkIn,
						checkOut: checkOut,
						adults: adults, 
						children: children,
						pets: pets,
						rooms: 1
			  }
			}

		  );
		  setRooms(result.data);

		};
		fetchData();
	  }, [checkIn, checkOut]);


    return(
        <div>

<style>
{`
.text-success {
            --bs-text-opacity: 1;
            color: #de1694 !important;
        }


`}
      
</style>


            <main>


			<section className="card-grid pt-0" style={{paddingBottom: 1 + 'rem'}}>
	<div className="container">
	<Gallery propertyImages={malonImages} />


        <div className="startFrom">
            Start from $199/night
        </div>
	</div>
</section>

<section class="py-0">
	<div class="container position-relative" >
		<div class="row mb-3" style={{backgroundImage: 'linear-gradient(to top left, transparent, turquoise)',
        width: 100 + '%',
        margin: 'auto',
        borderRadius: 10 + 'px',
        paddingTop: 10 + 'px',
        marginTop: -100 + 'px !important',
        position: 'relative',
        top: -35 + 'px',
        marginBottom: 1 + 'px'
        }}>
			<div className="col-12" style={{marginBottom: 1 + 'px'}}>
				<div className="d-lg-flex justify-content-lg-between mb-1">
					<div className="mb-2 mb-lg-0">
						<h1 className="fs-4" style={{fontSize: 'calc(1.125rem + 1.8vw) !important'}}>{malonName} </h1>
						<div className="row">
							<div className="col-8 col-sm-12">
								<div className="col-12 col-sm-6 malonAddress">
									<i className="bi bi-geo-alt me-2"></i>{malonAddress + ' ' + malonCity + ' ' + malonCountry}
								</div>
								<div className="col-12 col-sm-6"> 
									<a href="#malonMap"  className="ms-2 text-decoration-underline" >
										<i class="bi bi-eye-fill me-1"></i>{translate('viewOnMap')}
									</a>
								</div>
							</div>
							<div className="col-4 col-sm-12">
								<ul className="list-inline text-end">
									<li className="list-inline-item">
										<a href="#" className="btn btn-sm btn-light px-2"><i className="fa-solid fa-fw fa-heart"></i></a>
									</li>
									<li className="list-inline-item dropdown">
										<a href="#" className="btn btn-sm btn-light px-2" role="button" id="dropdownShare" data-bs-toggle="dropdown" aria-expanded="false">
											<i class="fa-solid fa-fw fa-share-alt"></i>
										</a>
										<ul class="dropdown-menu dropdown-menu-end min-w-auto shadow rounded" aria-labelledby="dropdownShare">
											<li><a className="dropdown-item" href="#"><i className="fab fa-twitter-square me-2"></i>Twitter</a></li>
											<li><a className="dropdown-item" href="#"><i className="fab fa-facebook-square me-2"></i>Facebook</a></li>
											<li><a className="dropdown-item" href="#"><i className="fab fa-linkedin me-2"></i>LinkedIn</a></li>
											<li><a className="dropdown-item" href="#"><i className="fa-solid fa-copy me-2"></i>Copy link</a></li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
						
						
						
					</div>

					
				</div>
			</div>
		</div>


	</div>
</section>

<section class="pt-0" style={{position: 'relative',
top: -50 + 'px'}}>
	<div class="container" data-sticky-container>

	<div className="row g-4 g-xl-5">
			<div className="col-xl-12 order-1" style={{marginTop: -10 + 'px', paddingTop:2 +'px'}}>
				<div className="vstack gap-5">

					<div className="card bg-transparent">
						

						<div className="card-body pt-4 p-0">
                        <div className="Row" style={{maxWidth: 100 + 'vw', overflowX: 'hidden', display:'flex',     marginBottom: 10 + 'px'}}>
                            <div className="col">
								<ul className="list-inline mb-2">
									<li className="list-inline-item me-0"><i class="fa-solid fa-star text-warning stars" style={{fontSize:25 + 'px' }}></i></li>
									<li className="list-inline-item me-0"><i class="fa-solid fa-star text-warning" style={{fontSize:25 + 'px'}}></i></li>
									<li className="list-inline-item me-0 medium"><i class="fa-solid fa-star text-warning" style={{fontSize:25 + 'px'}}></i></li>
									<li className="list-inline-item me-0 medium"><i class="fa-solid fa-star text-warning" style={{fontSize:25 + 'px'}}></i></li>
									<li className="list-inline-item me-0 medium"><i class="fa-solid fa-star-half-alt text-warning" style={{fontSize: 25 + 'px'}}></i></li>
								</ul>
							</div>
							<div className="col" style={{textAlign: 'right'}}>
								<font style={{fontSize: 'x-large', fontWeight: 'bold', color: '#000000'}}>9.2</font>/10 by Our guests
							</div>	
						</div>
							<div className="hstack gap-3 mb-3">
								<div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Free wifi">
									<i className="fa-solid fa-wifi"></i>
								</div>
								<div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Swimming Pool">
									<i className="fa-solid fa-swimming-pool"></i>
								</div>
								<div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Central AC">
									<i className="fa-solid fa-snowflake"></i>
								</div>
                                <div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Close to Beach">
                                    <i className="fa-solid fa-umbrella-beach"></i>
								</div>
                                <div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="GYM">
                                    <i className="fa-solid fa-dumbbell"></i>
								</div>
                                <div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Free parking">
                                    <i className="fa-solid fa-parking"></i>
								</div>
                                <div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Close to Shopping mall">
                                    <i className="fa-solid fa-bag-shopping"></i>
								</div>
                               
								<div className="icon-lg bg-light h5 rounded-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Free Service">
									<i className="fa-solid fa-concierge-bell"></i>
								</div>
							
                        </div>
						<hr style={{margin:10 + 'px', marginTop: -10 + 'px'}} />
                        <div className="col">
                            	<div className={language === 'he' ? 'SearchConditionsRTL' : 'SearchConditions'} style={{margin: 'auto',
									width: 100 + '%',
									background: '#f4f5f6',
									borderRadius: 10 + 'px',
									padding: 10 + 'px'}}>
										
										<SelectDatesBanner 
										checkIn={checkIn} checkOut={checkOut} adults={adults} children={children} pets={pets}
															onSelectDates={handleDates} 
															calanderPages={calanderPages}
															openDatesDisplay={openDatesDisplay}
															showGuestDiv = {showGuestDiv}

										/>

										
		
                                </div>
                            
                        </div>


						<div class="d-grid">
							<a href="#room-options" class="btn btn-lg btn-primary-soft mb-0" style={{backgroundImage: 'linear-gradient(to left, rgb(151 208 254), #ff00a1)', marginTop: 5 + 'px', border: 'none'}}> {translate('selectYourPalace')}</a>
						</div>



							{language === 'en' ? (
								<div>
									<p className="mb-3" style={{padding: 10 +'px', paddingBottom: 0}}><b>{malonDescription}</b></p>
									<p className="mb-0" style={{padding: 10 +'px', paddingTop: 0}}>{malonFullDescription}</p>
								</div>	
							): (
								<div style={{direction: 'rtl'}}>
									<p clasName="mb-3" style={{padding: 10 +'px', paddingBottom: 0}}><b>{malonDescriptionHe}</b></p>
									<p className="mb-0" style={{padding: 10 +'px', paddingTop: 0}}>{malonFullDescriptionHe}</p>
								 </div>
								  )}
						
							<div id="malonMap" className="malonMap">
								<div className="col-12">
									<div id="map" className="w-100 h-300px rounded" height="500" style={{border:0}} aria-hidden="false" tabIndex="0">
										<NewMap className="w-100 h-300px rounded" lat={googleX} lng={googleY} zoom={googleZ}/>
									</div>
								</div>	
							</div>
							
	
						</div>

						
					</div>
					<div id="room-options">
								{ malonData.roomsFeatures !== undefined && (
								<AvailableRooms 
									rooms={rooms}  
									malonMame={malonName} 
									roomsPhotosGallery={malonImages} 
									checkIn={checkIn}
									checkOut={checkOut}
									adults={adults}
									children={children}
									pets={pets}
									roomsFeatures={malonData.roomsFeatures}
									handleDates = {handleDates}
									openDatesDisplay={openDatesDisplay}
								/>)}         
							</div>
					<div class="card bg-transparent">
						<div class="card-header border-bottom bg-transparent px-0 pt-0">
							<h3 class="card-title mb-0">Amenities</h3>
						</div>

						<div class="card-body pt-4 p-0">
							<div class="row g-4">
								<div class="col-sm-6">
									<h6><i class="fa-solid fa-biking me-2"></i>Activities</h6>
									<ul class="list-group list-group-borderless mt-2 mb-0">
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Swimming pool
										</li>
                                        <li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Gym
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Beach
										</li>
								
									</ul>
								</div>
	
								<div class="col-sm-6">
									<h6><i class="fa-solid fa-credit-card me-2"></i>Payment Method</h6>
									<ul class="list-group list-group-borderless mt-2 mb-0">
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Credit card (Visa, Master card, American Express)
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Cash
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Debit Card
										</li>
                                        <li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Bank transfer
										</li>
                                        <li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Pay Pal
										</li>
									</ul>
								</div>
	
								<div class="col-sm-6">
									<h6><i class="fa-solid fa-concierge-bell me-2"></i>Services</h6>
									<ul class="list-group list-group-borderless mt-2 mb-0">
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Breakfast by demand
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Cleaning by demmand
										</li>
                                        <li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Pillow menu
										</li>
                                        <li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Mini bar
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>All chanels, Sport, Netflix and Disney
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Video games
										</li>
							
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Concierge
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Tours in Israel
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Laundry & Ironing Service
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Shabbat Lift
										</li>
                                        <li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>Free parking
										</li>
									</ul>
								</div>
	
								<div class="col-sm-6">
									<h6><i class="bi bi-shield-fill-check me-2"></i>Safety & Security</h6>
									<ul class="list-group list-group-borderless mt-2 mb-4 mb-sm-5">
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>24 / 7 Guard
										</li>
									</ul>
	
									<h6><i class="fa-solid fa-volume-up me-2"></i>Staff Language</h6>
									<ul class="list-group list-group-borderless mt-2 mb-0">
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>English
										</li>
										<li class="list-group-item pb-0">
											<i class="fa-solid fa-check-circle text-success me-2"></i>עברית
										</li>
						
									</ul>
								</div>
	
							</div>
						</div>
					</div>
                    
                    
	

		

					<div class="card bg-transparent">
						<div class="card-header border-bottom bg-transparent px-0 pt-0">
							<h3 class="mb-0">Hotel Policies</h3>
						</div>

						<div class="card-body pt-4 p-0">
							<ul class="list-group list-group-borderless mb-2">
								<li class="list-group-item d-flex">
									<i class="bi bi-check-circle-fill me-2"></i>This is a family farmhouse, hence we request you to not indulge.
								</li>
								<li class="list-group-item d-flex">
									<i class="bi bi-check-circle-fill me-2"></i>Drinking and smoking within controlled limits are permitted at the farmhouse but please do not create a mess or ruckus at the house.
								</li>
								<li class="list-group-item d-flex">
									<i class="bi bi-check-circle-fill me-2"></i>Drugs and intoxicating illegal products are banned and not to be brought to the house or consumed.
								</li>
								<li class="list-group-item d-flex">
									<i class="bi bi-x-circle-fill me-2"></i>For any update, the customer shall pay applicable cancellation/modification charges.
								</li>
							</ul>

							<ul class="list-group list-group-borderless mb-2">
								<li class="list-group-item h6 fw-light d-flex mb-0">
									<i class="bi bi-arrow-right me-2"></i>Check-in: Anytime After 1:00 pm
								</li>
								<li class="list-group-item h6 fw-light d-flex mb-0">
									<i class="bi bi-arrow-right me-2"></i>Check out: Anytime before 11:00 am
								</li>
								<li class="list-group-item h6 fw-light d-flex mb-0">
									<i class="bi bi-arrow-right me-2"></i>VIP check-in with building staff
								</li>
								<li class="list-group-item h6 fw-light d-flex mb-0">
									<i class="bi bi-arrow-right me-2"></i>Pets friendly
								</li>
								<li class="list-group-item h6 fw-light d-flex mb-0">
									<i class="bi bi-arrow-right me-2"></i>No parties or events
								</li>
								<li class="list-group-item h6 fw-light d-flex mb-0">
									<i class="bi bi-arrow-right me-2"></i>Smoking NOT allowed
								</li>
							</ul>

						</div>
					</div>
				</div>	
			</div>
            </div>
		</div> 
</section>

</main>

<div className="smartDatePicker" style={{ backgroundColor: 'rgb(239, 240, 240)', display: dateRangeDisplay,  borderRadius: 20 + 'px', color:'#000'}}>
	<div className="container selectors">
		<div className={`dateSelect content ${showSelect ? 'visible' : ''}`}>
			<SmarketDateRangePicker 
				onSelectDates={handleDates} 
				calanderPages={calanderPages} 
				closeDatesDisplay={closeDatesDisplay}
				showGuestDiv = {showGuestDiv}
			/>
		</div>
		<div className={`guestSelect content ${!showSelect ? 'visible' : ''}`}>
			<GuestsBoard 
				onSubmitNumberOfGuests={handleGuest}
				closeDatesDisplay={closeDatesDisplay} 
			/>
		</div>
		
	</div>										
 
</div>



<div className="modal fade" id="selectDatesModal" tabIndex="-1" aria-labelledby="selectDatesModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{maxWidth: 90 + '%'}}>
                  <div className="modal-content">
                    <div className="modal-header">
                    </div>
                    <div className="modal-body">
                    SELECT DATE HERERRERRERRER
					<SmarketDateRange 
						onSelectDates={handleDates} calanderPages={calanderPages} openPicker={openPicker}/>
                     </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
            

                  </div>
                </div>
              </div>

        </div>
    )

}

export default Palace