import React from 'react';
import './NewReservation.css';
import SmarketDateRange from '../SmarketDateRange';
import GuestSelection from '../SearchBar/GuestsSelection';
//import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
//import { NavDropdown } from 'react-bootstrap';
import ReservationItems from './RervationItems';
import PayBy from './PayBy';
import SelectCurrency from './Currency';
import { Datepicker } from '@mobiscroll/react';
import PaymentItems from './PaymentItems';
import axios from 'axios';
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const apiUrl = config.apiUrl;


const maxGuests = 16;

function generateUniqueID() {
  const timestamp = new Date().getTime();
  const randomNum = Math.floor(Math.random() * 10000); // You can adjust the range based on your needs
  const uniqueID = `${timestamp}-${randomNum}`;
  return uniqueID;
}


class AddNewReservationManual extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        agent: 0,
        guestFullName: '',
        guestCountryCode: 0,
        guestCountryName: '',
        guestEmail: '',
        guestPhone: '',
        currentGuest: {
          guestId: '',  
          fullName: '',
          country: '',
          phone: '',
          email: ''
        },
        malonId: 0,
        property: '',
        checkIn: '',
        checkOut: '',
        adults: 1,
        children: 0,
        pets: 0,
        guestsTxt: '',
        numberOfGuests: {},
        guestCost: 0,
        netRevenue: 0,
        currency: 'ILS',
        payBy: '',
        payment: '',
        paymentMethod: '',
        paymentStatus: '',
        paymentDateValue: '',
        total2BePaid: 0,
        paymentRecorded: 0 ,
        remain2Collect: 0,
        status: '',
        selectedGuests: 'Please select',
       guestBoardDisplay: 'none',
       reservationLine: {
        malonId: 0,
        property: '',
        checkIn: '',
        checkOut: '',
        adults: 0,
        children: 0,
        pets: 0,
        guestsTxt: '',
        guestCost: 0,
        netRevenue: 0,
        currency: '',
        payBy: '',
       },
       reservationItems: [],
       paymentItems:[]

    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
     this.handleIncreaseAdults = this.handleIncreaseAdults.bind(this);
     this.handleDecreaseAdults = this.handleDecreaseAdults.bind(this);
     this.handleIncreaseChildren = this.handleIncreaseChildren.bind(this);
     this.handleDecreaseChildren = this.handleDecreaseChildren.bind(this);
     this.handleIncreasePets = this.handleIncreasePets.bind(this);
     this.handleDecreasePets = this.handleDecreasePets.bind(this);
     this.handleGuestsBoardDisplay = this.handleGuestsBoardDisplay.bind(this);
     this.handleNumberOfGuests = this.handleNumberOfGuests.bind(this);
     this.onDateSelected = this.onDateSelected.bind(this);
     this.selectGuestId = this.selectGuestId.bind(this);
     //this.total2BePaid4Reservation = this.total2BePaid4Reservation.bind(this);
    }
 
    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    handleDates = (date) => {

 
      if (date[1]){
        
        this.setState({
          //add 3 hours because UTC+3
          checkIn: date[0], checkOut: date[1]
        });

      } 
    
      
  }

  submitNewReservation2Server = async(e) => {

    e.preventDefault();
    try {
      const res = await axios.post(apiUrl + '/reservation/CreateNewManualReservation', {
        agent: this.state.agent,
        guest: this.state.currentGuest,
        reservationItems: this.state.reservationItems,
        paymentItems: this.state.paymentItems
      });
    } catch(err) {
    }

  };


    
  

    handleSelectedGuests(){
        let txt1 = 'Please select';
        if (this.state.adults > 0){
            txt1 = 'Adults: ' + this.state.adults;
            if (this.state.children > 0){
                txt1 += ', Kids: ' + this.state.children;
            } 
            if (this.state.pets > 0){
                txt1 += ', Pets: ' + this.state.pets;
            }
        } else {
            if (this.state.children > 0){
                txt1 += 'Kids: ' + this.state.children;
                if (this.state.pets > 0){
                    txt1 += ', Pets: ' + this.state.pets;
                }
            } 
        }
        this.setState({selectedGuests: txt1});
    }
  
    handleIncreaseAdults() {
      let adults = this.state.adults;
      if (adults<maxGuests){
        //this.setState({adults: adults + 1});  
        this.setState({
          adults: adults + 1
        },() => { this.handleSelectedGuests(); })
      }
      }
    
    handleDecreaseAdults() {
        let adults = this.state.adults;
        if (adults>0){
          //this.setState({adults: adults - 1});
          if (adults<maxGuests){
            this.setState({
              adults: adults - 1
            },() => { this.handleSelectedGuests(); })
          }
        }
        
      }
      handleIncreaseChildren() {
      let children = this.state.children;
      if (children<maxGuests){
        //this.setState({children: children + 1});
        this.setState({
          children: children + 1
        },() => { this.handleSelectedGuests(); })
      }
        
    }
    handleDecreaseChildren() {
        let children = this.state.children;
        if (children>0){
          this.setState({
            children: children - 1
          },() => { this.handleSelectedGuests(); })
        }
      }
      handleIncreasePets() {
      let pets = this.state.pets;
      if (pets<maxGuests){
        this.setState({
          pets: pets + 1
        },() => { this.handleSelectedGuests(); })
      }

        
    }
    handleDecreasePets() {
        let pets = this.state.pets;
        if (pets>0){
          this.setState({
            pets: pets - 1
          },() => { this.handleSelectedGuests(); })
        }
      }
      /*
    handleSubmit(event) {
      alert('A name was submitted: ' + this.state.value);
      event.preventDefault();
    }
*/

    handleGuestsBoardDisplay(){
      if (this.state.guestBoardDisplay === 'none'){
        this.setState({guestBoardDisplay: 'block'});
      } else{
        this.setState({guestBoardDisplay: 'none'});
      }
    }
    
    handleClickOutside(event) {
      if (this.state.handleGuestsBoardDisplay === 'block'){
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.setState({guestBoardDisplay: 'none'});
        }
      }
    }

    selectGuestId = () => {
      function getGuestCode(agent){
        if (agent>= 2) {
          switch(agent) {
            case 2:
              return 'ME';
              break;
            case 3:
              return 'AR';
              break;
            case 4:
              return 'BK';
              break;
            case 5:
              return 'EX';
              break;
            case 6:
              return 'TR';
              break;
          }
        } else {
          return 'PP'
        }
      }
      
      return (getGuestCode(this.state.agent) + Date.now());
    }

    handleGuestSubmit = (e) => {
      e.preventDefault();
      console.log(this.state.guestFullName);
      console.log(this.state.guestCountryName);
      console.log(this.state.guestPhone);
      console.log(this.state.guestEmail);
      let guestTemp = {};
      guestTemp.guestId = 'PP'  + Date.now();
      guestTemp.fullName = this.state.guestFullName;
      guestTemp.country = this.state.guestCountryName;
      guestTemp.phone = this.state.guestPhone;
      guestTemp.email = this.state.guestEmail;
      this.setState({currentGuest: guestTemp});
    }

    handleNumberOfGuests = (guests) => {
      console.log('handleGuests');
      console.log(guests);
        this.setState({numberOfGuests: guests});
        this.setState({adults: guests.adults});
        this.setState({children: guests.children});
        this.setState({pets: guests.pets});
        this.setState({guestsTxt: guests.selectedGuestsTxt})
    }


    handleNewReservationLine = (e) => {
      e.preventDefault();
      let newReservationLineTemp = {};
      newReservationLineTemp.key = 'RL' + Date.now();
      newReservationLineTemp.malonId = this.state.malonId;
      newReservationLineTemp.property = this.state.property;
      newReservationLineTemp.checkIn = this.state.checkIn;
      newReservationLineTemp.checkOut = this.state.checkOut;
      newReservationLineTemp.adults = this.state.adults;
      newReservationLineTemp.children = this.state.children;
      newReservationLineTemp.pets = this.state.pets;
      newReservationLineTemp.guestsTxt = this.state.guestsTxt;
      newReservationLineTemp.guestCost = this.state.guestCost;
      newReservationLineTemp.netRevenue = this.state.netRevenue;
      newReservationLineTemp.currency = this.state.currency;
      newReservationLineTemp.payBy = this.state.payBy;
      console.log('newReservationLineTemp');
      console.log(newReservationLineTemp);
      let tempReservationItems = this.state.reservationItems;
      tempReservationItems.push(newReservationLineTemp);
      this.setState({reservationItems: tempReservationItems})
      let tempTotal2BePaid = this.state.total2BePaid;
      if (newReservationLineTemp.payBy === 'Agent'){
        tempTotal2BePaid += parseInt(newReservationLineTemp.netRevenue);
          } else {
            tempTotal2BePaid += parseInt(newReservationLineTemp.guestCost);
          }
          this.setState({total2BePaid: tempTotal2BePaid});

    }
    

    handleNewPaymentLine = (e) => {
      e.preventDefault();
      let newPaymentLineTemp = {};
      newPaymentLineTemp.key = generateUniqueID();
      newPaymentLineTemp.payBy = this.state.payBy;
      newPaymentLineTemp.payment = this.state.payment;
      let tempPaymentRecorded = this.state.paymentRecorded + this.state.payment;
      newPaymentLineTemp.currency = this.state.currency;
      newPaymentLineTemp.paymentMethod = this.state.paymentMethod;
      newPaymentLineTemp.paymentDateValue = this.state.paymentDateValue;
      newPaymentLineTemp.paymentStatus = this.state.paymentStatus;
      let tempPaymentItems = this.state.paymentItems;
      tempPaymentItems.push(newPaymentLineTemp);
      this.setState({paymentItems: tempPaymentItems});
      this.setState({paymentRecorded: tempPaymentRecorded});

    }
    


    onDateSelected(ev) {
      const date = ev.value;
      let dateText = date.getDate()+ '/' + (date.getMonth()+1) + '/' + date.getFullYear();
      this.setState({paymentDateValue: dateText});

      }
  
    render() {
        return (
            <div className="container" style={{width: 95 + '%', maxWidth: 1040 + 'px', margin:'auto', border: '#000 1px solid', borderRadius:10+'px', padding:20+'px'}}>
              <div className="alert alert-info" role="alert">
                Add New Reservation Manual
              </div>
              <form>
                <div>
                  <h4> Agent Details</h4>
                  <select value={this.state.agent} onChange={(e) => this.setState({agent: e.target.value})}  className="form-select" aria-label="Default select example">
                    <option key={0} value={0}>Select an agent </option>
                    <option key={1} value={1}>Prince Palace</option>
                    <option key={2} value={2}>Mellonot</option>
                    <option key={3} value={3}>Airbnb</option>
                    <option key={4} value={4}>Booking</option>
                    <option key={5} value={5}>Expedia</option>
                    <option key={6} value={6}>Trip Advisor</option>
                  </select>
                  <hr />
                </div>
                <div>
                  <h4> Guest Details </h4>
                  <div>
                    <div className="row">
                      <div className="col">
                        <br />
                        <button type="button" class="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#newGuestModal"> + New Guest </button>
                      </div>
                      <div className="col">
                        Search for exciting guest account: 
                        <div class="input-group mb-3">
                          <input type="text" className="form-control" id="guestSearch" placeholder="Type guest name" aria-label="guestSearch" aria-describedby="guestSearchButton" />
                          <button className="btn btn-outline-info btn-outline-secondary" type="button" id="guestSearchButton">Search</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <fieldset disabled>
                  <div className="row">
                  <div className="mb-3">
                      <label htmlFor="guestNumber" className="form-label">Guest Number</label>
                      <input type="guestNumber" value={this.state.currentGuest.guestId} className="form-control" id="guestNumber" placeholder="" />
                    </div>
                  </div>
                  <div className="row">
                   <div className="col">
                    <div className="mb-3">
                      <label htmlFor="fullName" className="form-label">Full Name</label>
                      <input type="fullName" value={this.state.currentGuest.fullName} className="form-control" id="fullName" placeholder="" />
                    </div>
                   </div>
                   <div className="col">
                      <div className="mb-3">
                        <label htmlFor="Country" className="form-label">Country</label>
                        <input type="text" value={this.state.currentGuest.country} className="form-control" id="country" placeholder="" />
                      </div>
                   </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="phone" className="form-label">Phone</label>
                          <input type="phone" value={this.state.currentGuest.phone} className="form-control" id="phone" placeholder="" />
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">Email</label>
                          <input type="email" value={this.state.currentGuest.email} className="form-control" id="email" placeholder="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </fieldset>
                  <hr />
                </div>
                <div>
                  <h4>Reservation Details</h4>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <button type="button" class="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#newReservationItemModal"> + New Reservation Item </button>
                      </div>
                    </div>
                  </div>
                  <div className="reservationTable" style={{overflowX: 'auto'}}>
                    <table>
                      <tr>
                        <th>Property</th>
                        <th>Check-In</th>
                        <th>Check-Out</th>
                        <th>Nights</th>
                        <th>Guests</th>
                        <th>Guest Cost</th>
                        <th>Net Revenue</th>
                        <th>Pay By</th>
                        <th>Edit</th>
                      </tr>
                      <ReservationItems reservationLines={this.state.reservationItems} />
                      
                    </table>
                    <div>
                      Total to be paid for this reservation: {this.state.total2BePaid}
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                <h4>Payment Details</h4>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <button type="button" className="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#newPaymentItemModal"> + New Payment </button>
                      </div>
                    </div>
                  </div>
                  <div className="reservationTable" style={{overflowX: 'auto'}}>
                    <table>
                      <tr>
                        <th>Pay By</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Method</th>
                        <th>Value Day</th>
                        <th>Status</th>
                        <th>Edit</th>
                      </tr>
                      <PaymentItems paymentLines={this.state.paymentItems} />

                    </table>
                    <div className="remainBalance" style={{textAlign: 'center', padding: 10 + 'px'}}>
                      <h5>TBD LATER: Ramain Balance to collect: {this.state.total2BePaid - this.state.paymentRecorded}</h5>
                    </div>
                  </div>
                </div>
              </form>
              
              <div className="modal fade" id="newGuestModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header"  style={{backgroundColor: '#9eeaf9'}}>
                      <h1 className="modal-title fs-5" id="exampleModalLabel">Add New Guest</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={this.handleGuestSubmit}>

                    <div className="modal-body">
                      <div className="row">
                        <div className="col">
                         <div className="mb-3">
                            <label htmlFor="fullName" className="form-label">Full Name</label>
                            <input type="text" value={this.state.guestFullName} onChange={(e) => this.setState({guestFullName: e.target.value})} className="form-control" name="fullName" id="fullName" placeholder="Full Name" />
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="Country" className="form-label">Country</label>
                            <select value={this.state.guestCountryName} onChange={(e) => this.setState({guestCountryName: e.target.value, guestCountryCode: e.target.key})} className="form-select" aria-label="Default select example" name="country">
                              <option key={0} value="Israel">Select Country</option>
                              <option key={83} value="Israel">Israel</option>
                              <option key={186} value="USA">USA</option>
                              <option key={185} value="UK">UK</option>
                              <option key={61} value="France">France</option>
                              <option key={164} value="Spain">Spain</option>
                              <option key={143} value="Russia">Russia</option>
                              <option key={183} value="Ukraine">Ukraine</option>
                              <option key={65} value="Germany">Germany</option>
                              <option key={139} value="Poland">Poland</option>
                              <option key={37} value="China">China</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="mb-3">
                              <label htmlFor="phone" className="form-label">Phone</label>
                              <input type="phone" value={this.state.guestPhone} onChange={(e) => this.setState({guestPhone: e.target.value})} className="form-control" name="phone" id="phone" placeholder="Phone" />
                            </div>
                          </div>
                          <div className="col">
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">Email</label>
                              <input type="email" value={this.state.guestEmail} onChange={(e) => this.setState({guestEmail: e.target.value})} className="form-control" name="email" id="email" placeholder="Email" />
                            </div>
                          </div>
                        </div>
                      </div>
                     </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
                    </div>
                    </form>

                  </div>
                </div>
              </div>

              <div className="modal fade" id="newPaymentItemModal" tabIndex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header"  style={{backgroundColor: '#9eeaf9'}}>
                      <h1 className="modal-title fs-5" id="paymentModalLabel">Add New Payment Item</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                          <PayBy payBy={this.state.payBy} onChange={(e) => this.setState({payBy: e.target.value})} />

                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <label>
                              Amount:
                              <input type="text" value={this.state.payment} onChange={(e) => this.setState({payment: e.target.value})} class="form-control"/>
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3">
                            <SelectCurrency currency={this.state.currency} onChange={(e) => this.setState({currency: e.target.value})}/>
                          </div>
                        </div>
                      </div>  
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                          <label htmlFor="Method" className="form-label">Method</label>
                            <select value={this.state.paymentMethod} onChange={(e) => this.setState({paymentMethod: e.target.value})} className="form-select" aria-label="Payment Method" name="Method">
                              <option key={0} value={'Select'}>Select</option>
                              <option key={1} value="Bank Transfer">Bank Transfer</option>
                              <option key={2} value="Credit Card">Credit Card</option>
                              <option key={3} value="Cash">Cash</option>
                              <option key={4} value="At Check In">At Check In</option>
                              <option key={5} value="ResortBit">ResortBit</option>
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="mb-3" style={{border: 'solid 1px #fff'}}>
                          <label htmlFor="DayValue" className="form-label">Day Value</label>
                            <Datepicker 
                              controls={['calendar']}                 
                              onChange={this.onDateSelected}
                              dateFormat={"DD/MM/YYYY"}
                            />
                          </div>
                        </div>
                      </div> 
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                          <label htmlFor="Method" className="form-label">Status</label>
                            <select value={this.state.paymentStatus} onChange={(e) => this.setState({paymentStatus: e.target.value})} className="form-select" aria-label="Default select example" name="country">
                            <option key={0} value={''}>Select</option>
                              <option key={1} value="Guaranty">Guaranty</option>
                              <option key={2} value="Paid">Paid</option>
                              <option key={3} value="To Collect">To Collect</option>
                            </select>
                          </div>
                        </div>

                      </div>   
                   </div>
                   <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={this.handleNewPaymentLine} data-bs-dismiss="modal">Add new payment</button>
                    </div>
                  </div>
                </div>
              </div>     





              <div className="modal fade" id="newReservationItemModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header"  style={{backgroundColor: '#9eeaf9'}}>
                      <h1 className="modal-title fs-5" id="exampleModalLabel">Add New Reservation Item</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <div className="row">
                   <div className="col">
                   <div className="mb-3">
                        <label htmlFor="Location" className="form-label">Location</label>
                        <select className="form-select"  value={this.state.malonId} onChange={(e) => this.setState({malonId: e.target.value})} aria-label="Default select">
                          <option key={0} value={0}>Select Location</option>
                          <option key={9} value={9}>Tel Aviv Center</option>
                          <option key={1} value={1}>Netanya Beach</option>
                          <option key={8} value={8}>Hadera Baeach</option>
                          <option key={11} value={11}>Herzelia Beach</option>
                        </select>
                      </div>
                   </div>
                   <div className="col">
                   <div className="mb-3">
                        <label htmlFor="Location" className="form-label">Apartment</label>
                        <select value={this.state.property} onChange={(e) => this.setState({property: e.target.value})} className="form-select" aria-label="Default select example">
                          <option key={0} value={0}>Select Apartment</option>
                          <option key={1} value={9}>Prince Palace Tel Aviv Center - Top Sky 2BR</option>
                          <option key={2} value={1}>Prince Palace Netanya Beach - Royal Palace 2BR</option>
                          <option key={3} value={2}>Prince Palace Netanya Beach - Deluxe Palace 2BR</option>
                          <option key={4} value={3}>Prince Palace Netanya Beach - Platinium Suite 1BR</option>
                          <option key={5} value={4}>Prince Palace Netanya Beach - Gold Suite 1BR</option>
                          <option key={6} value={5}>Prince Palace Netanya Beach - Penthouse Palace 2BR</option>
                          <option key={7} value={8}>Prince Palace Hadera Beach - Gold Palace 3BR</option>
                          <option key={8} value={7}>Prince Palace Hadera Beach - Silver Palace 3BR</option>
                          <option key={9} value={12}>Prince Palace Herzelia Beach - Studio Palace</option>
                        </select>
                      </div>
                   </div>
                  </div> 
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                      <h6>
                        Dates
                      </h6>
                        <div style={{border: 1 +'px solid #000 '}}>
                            <SmarketDateRange onSelectDates={this.handleDates}/>
                        </div>
                      </div>
                      </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                      <h6>Guests</h6>
                        <GuestSelection onSubmitNumberOfGuests={this.handleNumberOfGuests} />
                      </div>
                    </div>
                  </div>
                  <hr />
                      <h6>Reservation Cost</h6>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                      
                        <label>
                          Guest Cost:
                          <input type="text" value={this.state.guestCost} onChange={(e) => this.setState({guestCost: e.target.value})} />
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label>
                          Net Revenue:
                          <input type="text" value={this.state.netRevenue} onChange={(e) => this.setState({netRevenue: e.target.value})} />
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <SelectCurrency currency={this.state.currency} onChange={(e) => this.setState({currency: e.target.value})}/>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <PayBy payBy={this.state.payBy} onChange={(e) => {this.setState({payBy: e.target.value})} }/>
                      </div>
                    </div>
                  </div>


                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={this.handleNewReservationLine} data-bs-dismiss="modal" >Add to Reservation</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-grid gap-2" style={{marginTop:15+'px'}}>
         
                <button class="btn btn btn-outline-info" type="button" onClick={this.submitNewReservation2Server}>Save Reservation in System</button>

              </div>
            
            </div>

            

        );





    }
  }

  export default AddNewReservationManual;
