import React from 'react';



class Header extends React.Component {
    constructor(props) {
      super(props);
      this.state = {


    };
}

  
    render() {
      return (
        <>
            {/* </><!-- Header START --> */}
            <header className="navbar-light header-sticky">
                {/*<!-- Logo Nav START -->  */}
                    <nav className="navbar navbar-expand-xl">
                        <div className="container">
                            <div className="leftSide" style={{width:25 +'%', fontWeight: 'bold'}}>
                                <img src="assets/icons/burger-menu.svg" alt="menu" className="menu-btn" id="open-menu" style={{display:'none'}} />
                                09-8844448
                             </div>


                            
                            <div className="centerSide" style={{width:50 +'%', 
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'}}>
                                <a className="navbar-brand" href="/">
                                    <img className="logo1" src="/Images/princepalace_logo_gili.png" alt="the Prince Palace" style={{width:250 + 'px', margin: 'auto', fontWeight: 'bold', fontSize:40+'px'}} />
                                </a>
                            </div>
                            <div className="rightSide" style={{width:25 +'%'}}>
                            <ul className="nav flex-row align-items-center list-unstyled ms-xl-auto" style={{float: 'right', display: 'none'}}>


                                {/*<!-- Notification dropdown START -->*/}
                                <li className="nav-item dropdown me-3">
                                    <a className="nav-notification btn btn-light p-0 mb-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                        <i className="bi bi-bell fa-fw"></i>
                                    </a>
                                    <span className="notif-badge animation-blink"></span>

                                    <div className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0">
                                        <div className="card bg-transparent">
                                            <div className="card-header bg-transparent d-flex justify-content-between align-items-center border-bottom">
                                                <h6 className="m-0">Notifications <span className="badge bg-danger bg-opacity-10 text-danger ms-2">4 new</span></h6>
                                                <a className="small" href="#">Clear all</a>
                                            </div>

                                            <div className="card-body p-0">
                                                <ul className="list-group list-group-flush list-unstyled p-2">
                                                    <li>
                                                        <a href="#" className="list-group-item list-group-item-action rounded notif-unread border-0 mb-1 p-3">
                                                            <h6 className="mb-2">New! Booking flights from New York ✈️</h6>
                                                            <p className="mb-0 small">Find the flexible ticket on flights around the world. Start searching today</p>
                                                            <span>Wednesday</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="list-group-item list-group-item-action rounded border-0 mb-1 p-3">
                                                            <h6 className="mb-2">Sunshine saving are here 🌞 save 30% or more on a stay</h6>
                                                            <span>15 Nov 2022</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="card-footer bg-transparent text-center border-top">
                                                <a href="#" className="btn btn-sm btn-link mb-0 p-0">See all incoming activity</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {/*<!-- Notification dropdown END --> */}

                                {/*<!-- Profile dropdown START --> */}
                                <li className="nav-item dropdown">
                                    <a className="avatar avatar-sm p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img className="avatar-img rounded-2" src="assets/images/avatar/01.jpg" alt="avatar" />
                                    </a>

                                    <ul className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" aria-labelledby="profileDropdown">
                                        <li className="px-3 mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="avatar me-3">
                                                    <img className="avatar-img rounded-circle shadow" src="assets/images/avatar/01.jpg" alt="avatar" />
                                                </div>
                                                <div>
                                                    <a className="h6 mt-2 mt-sm-0" href="#">Lori Ferguson</a>
                                                    <p className="small m-0">example@gmail.com</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li> <hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" href="#"><i className="bi bi-bookmark-check fa-fw me-2"></i>My Bookings</a></li>
                                        <li><a className="dropdown-item" href="#"><i className="bi bi-heart fa-fw me-2"></i>My Wishlist</a></li>
                                        <li><a className="dropdown-item" href="#"><i className="bi bi-gear fa-fw me-2"></i>Settings</a></li>
                                        <li><a className="dropdown-item" href="#"><i className="bi bi-info-circle fa-fw me-2"></i>Help Center</a></li>
                                        <li><a className="dropdown-item bg-danger-soft-hover" href="#"><i className="bi bi-power fa-fw me-2"></i>Sign Out</a></li>
                                        <li> <hr className="dropdown-divider" /></li>
                                        <li>
                                                <div className="nav-pills-primary-soft theme-icon-active d-flex justify-content-between align-items-center p-2 pb-0">
                                                    <div className="row">
                                                        <div className='col'>
                                                            <span>Language:</span>
                                                        </div>
                                                        <div className='col'>
                                                        <button type="button" className="btn btn-link nav-link text-primary-hover mb-0 p-0" data-bs-theme-value="light" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Light">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sun fa-fw mode-switch" viewBox="0 0 16 16">
                                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                                                            <use href="#"></use>
                                                        </svg>
                                                    </button>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className='col'>
                                                            <span>Currency:</span>
                                                        </div>
                                                        <div className='col'>
                                                        <button type="button" className="btn btn-link nav-link text-primary-hover mb-0 p-0" data-bs-theme-value="light" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Light">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sun fa-fw mode-switch" viewBox="0 0 16 16">
                                                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                                                            <use href="#"></use>
                                                        </svg>
                                                    </button>    
                                                        </div>
                                                    </div>
             

                                                </div>
                                            </li> 

                                        </ul>
                                          
                                     

                                    </li>
                                    
                                </ul>
                                {/*<!-- Profile and Notification START --> */}
                            </div>    
                            </div>
                        </nav>
                </header>
            {/*<!-- Header END --> */}
        </>  

      );
    }
  }

  export default Header;
