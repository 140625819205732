import React from 'react';
import '../App.css';
import axios from 'axios';
import bootstrap from 'bootstrap';



class TravelAccount extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            account: {

            }
        }
    }

    componentDidMount() {
        
        axios.post(`https://localhost:8000/getAvailablerooms/`, { parameters:{

        } }).then(res => {
            //Do something afte call
            const rooms = res.data;
            this.setState({ Availablerooms: rooms });
          })

          
      }


      render(){
        return (
          <main>
  <style>
{`
.text-success {
            --bs-text-opacity: 1;
            color: #de1694 !important;
        }
        .SearchConditions{
            margin: auto;
            width: 350px;
            background: #f4f5f6;
            height: 100px;
            border-radius: 10px;
            padding: 10px
        }

`}
</style>
     
          <section class="pt-3">
            <div class="container">
              <div class="row">
          
                {/*<!-- Sidebar START -->*/}
                <div class="col-lg-4 col-xl-3">
                  <div class="offcanvas-lg offcanvas-end" tabindex="-1" id="offcanvasSidebar" >
                    <div class="offcanvas-header justify-content-end pb-2">
                      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasSidebar" aria-label="Close"></button>
                    </div>
          
                    <div class="offcanvas-body p-3 p-lg-0">
                      <div class="card bg-light w-100">
          
                        {/*<!-- Edit profile button -->*/}
                        <div class="position-absolute top-0 end-0 p-3">
                          <a href="#" class="text-primary-hover" data-bs-toggle="tooltip" data-bs-title="Edit profile">
                            <i class="bi bi-pencil-square"></i>
                          </a>
                        </div>
          
                        <div class="card-body p-3">
                          <div class="text-center mb-3">
                            <div class="avatar avatar-xl mb-2">
                              <img class="avatar-img rounded-circle border border-2 border-white" src="../assets/images/avatar/01.jpg" alt="" />
                            </div>
                            <h6 class="mb-0">Jacqueline Miller</h6>
                            <a href="#" class="text-reset text-primary-hover small">hello@gmail.com</a>
                            <hr />
                          </div>
          
                          <ul class="nav nav-pills-primary-soft flex-column">
                            <li class="nav-item">
                              <a class="nav-link active" href="account/profile"><i class="bi bi-person fa-fw me-2"></i>My Profile</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="account/booking"><i class="bi bi-ticket-perforated fa-fw me-2"></i>My Bookings</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="account/travelers"><i class="bi bi-people fa-fw me-2"></i>Travelers</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="account/payment"><i class="bi bi-wallet fa-fw me-2"></i>Payment Details</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="account/wishlist"><i class="bi bi-heart fa-fw me-2"></i>Wishlist</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="account/setting"><i class="bi bi-gear fa-fw me-2"></i>Settings</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" href="account/delete"><i class="bi bi-trash fa-fw me-2"></i>Delete Profile</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link text-danger bg-danger-soft-hover" href="#"><i class="fas fa-sign-out-alt fa-fw me-2"></i>Sign Out</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>
          
                <div class="col-lg-8 col-xl-9">
          
                  <div class="d-grid mb-0 d-lg-none w-100">
                    <button class="btn btn-primary mb-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
                      <i class="fas fa-sliders-h"></i> Menu
                    </button>
                  </div>
          
                  <div class="vstack gap-4">
                    <div class="bg-light rounded p-3">
                      <div class="overflow-hidden">
                        <h6>Complete Your Profile</h6>
                        <div class="progress progress-sm bg-success bg-opacity-10">
                          <div class="progress-bar bg-success aos" role="progressbar" data-aos="slide-right" data-aos-delay="200" data-aos-duration="1000" data-aos-easing="ease-in-out" style={{width: 85 + '%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                          <span class="progress-percent-simple h6 fw-light ms-auto">85%</span>
                        </div>
                      </div>
                      <p class="mb-0">Get the best out of booking by adding the remaining details!</p>
                      </div>
                      <div class="bg-body rounded p-3 mt-3">
                        <ul class="list-inline hstack flex-wrap gap-2 justify-content-between mb-0">
                          <li class="list-inline-item h6 fw-normal mb-0"><a href="#"><i class="bi bi-check-circle-fill text-success me-2"></i>Verified Email</a></li>
                          <li class="list-inline-item h6 fw-normal mb-0"><a href="#"><i class="bi bi-check-circle-fill text-success me-2"></i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>Verified Mobile Number</a></li>
                          <li class="list-inline-item h6 fw-normal mb-0"><a href="#" class="text-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>Complete Basic Info</a></li>
                        </ul>
                      </div>
                    </div>
          
                    <div class="card border">
                      <div class="card-header border-bottom">
                        <h4 class="card-header-title">Personal Information</h4>
                      </div>
          
                      <div class="card-body">
                        <form class="row g-3">
                          <div class="col-12">
                            <label class="form-label">Upload your profile photo<span class="text-danger">*</span></label>
                            <div class="d-flex align-items-center">
                              <label class="position-relative me-4" for="uploadfile-1" title="Replace this pic">
                                <span class="avatar avatar-xl">
                                  <img id="uploadfile-1-preview" class="avatar-img rounded-circle border border-white border-3 shadow" src="../assets/images/avatar/01.jpg" alt="" />
                                </span>
                              </label>
                              <label class="btn btn-sm btn-primary-soft mb-0" for="uploadfile-1">Change</label>
                              <input id="uploadfile-1" class="form-control d-none" type="file" />
                            </div>
                          </div>
          
                          <div class="col-md-6">
                            <label class="form-label">Full Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" value="Jacqueline Miller" placeholder="Enter your full name" />
                          </div>
          
                          <div class="col-md-6">
                            <label class="form-label">Email address<span class="text-danger">*</span></label>
                            <input type="email" class="form-control" value="hello@gmail.com" placeholder="Enter your email id" />
                          </div>
          
                          <div class="col-md-6">
                            <label class="form-label">Mobile number<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" value="222 555 666" placeholder="Enter your mobile number" />
                          </div>
          
                          <div class="col-md-6">
                            <label class="form-label">Nationality<span class="text-danger">*</span></label>
                            <select class="form-select js-choice">
                              <option value="">Select your country</option>
                              <option>USA</option>
                              <option selected>Paris</option>
                              <option>India</option>
                              <option>UK</option>
                            </select>
                          </div>
          
                          <div class="col-md-6">
                            <label class="form-label">Date of Birth<span class="text-danger">*</span></label>
                            <input type="text" class="form-control flatpickr" value="29 Aug 1996" placeholder="Enter date of birth" data-date-format="d M Y" />
                          </div>
          
                          <div class="col-md-6">
                            <label class="form-label">Select Gender<span class="text-danger">*</span></label>
                            <div class="d-flex gap-4">
                              <div class="form-check radio-bg-light">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked="" />
                                <label class="form-check-label" for="flexRadioDefault1">
                                  Male
                                </label>
                              </div>
                              <div class="form-check radio-bg-light">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                <label class="form-check-label" for="flexRadioDefault2">
                                  Female
                                </label>
                              </div>
                              <div class="form-check radio-bg-light">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                <label class="form-check-label" for="flexRadioDefault3">
                                  Others
                                </label>
                              </div>
                            </div>
                          </div>
          
                          <div class="col-12">
                            <label class="form-label">Address</label>
                            <textarea class="form-control" rows="3" spellcheck="false">2119 N Division Ave, New Hampshire, York, United States</textarea>
                          </div>
          
                          <div class="col-12 text-end">
                            <a href="#" class="btn btn-primary mb-0">Save Changes</a>
                          </div>
                        </form>
                      </div>
                    </div>
          
                    <div class="card border">
                      <div class="card-header border-bottom">
                        <h4 class="card-header-title">Update email</h4>
                        <p class="mb-0">Your current email address is <span class="text-primary">example@gmail.com</span></p>
                      </div>
          
                      <div class="card-body">
                        <form>
                          <label class="form-label">Enter your new email id<span class="text-danger">*</span></label>
                          <input type="email" class="form-control" placeholder="Enter your email id" />
          
                          <div class="text-end mt-3">
                            <a href="#" class="btn btn-primary mb-0">Save Email</a>
                          </div>
                        </form> 
                      </div>
                    </div>
          
                    <div class="card border">
                      <div class="card-header border-bottom">
                        <h4 class="card-header-title">Update Password</h4>
                        <p class="mb-0">Your current email address is <span class="text-primary">example@gmail.com</span></p>
                      </div>
          
                      <form class="card-body">
                        <div class="mb-3">
                          <label class="form-label">Current password</label>
                          <input class="form-control" type="password" placeholder="Enter current password" />
                        </div>
                        <div class="mb-3">
                          <label class="form-label"> Enter new password</label>
                          <div class="input-group">
                            <input class="form-control fakepassword" placeholder="Enter new password" type="password" id="psw-input" />
                            <span class="input-group-text p-0 bg-transparent">
                              <i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                            </span>
                          </div>
                        </div>
                        <div class="mb-3">
                          <label class="form-label">Confirm new password</label>
                          <input class="form-control" type="password" placeholder="Confirm new password" />
                        </div>
          
                        <div class="text-end">
                          <a href="#" class="btn btn-primary mb-0">Change Password</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </section>
         
          </main>
        );
      }
         
        
}

export default  TravelAccount;

