import React from 'react';
import '../Catalogue/catalogue.css';
//import axios from 'axios';
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const url = config.url;
const imagesUrl = config.imagesUrl;


function RoomBooking(props){
        let roomCounting = '';
        if (props.totalReservationItems>1){
            roomCounting = 'Property #' + props.reservationItem.reservationItemId + ' of ' + props.totalReservationItems + ':'
        }

        
        return (
        <>
            {roomCounting}
     
        <div className="itemCard" style={{borderColor:'pink', borderSize: 1 + 'px', border: 'solid #cccccc', padding:5+'px', margin:4+'px', borderRadius: 20+'px'}}>
                    <div className="card mb-4">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-6 col-md-4 card-img-scale-wrapper rounded-3" style={{height: 200 + 'px'}}>
                                                        <img src={imagesUrl + props.reservationItem.propertyPhoto} className="card-img" alt="" style={{height: 200 + 'px'}}/>
                                                    </div>

                                                   

                                                    <div className="col-sm-6 col-md-8">
                                                        <div className="card-body pt-3 pt-sm-0 p-0">
                                                        <h6 className="card-title"><a href="#">{props.reservationItem.malonName}</a></h6>

                                                            <h5 className="card-title"><a href="#">{props.reservationItem.roomName}</a></h5>
                                                            <p className="small mb-2"><i className="bi bi-geo-alt me-2"></i>{props.reservationItem.featuresShortDescription}</p>                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div className="row g-4">
                                                <div className="col-lg-4">
                                                    <div className="bg-light py-3 px-4 rounded-3">
                                                        <h6 className="fw-light small mb-1">Check-in</h6>
                                                         <small>{props.reservationItem.checkIn.day}</small>


                                                        <h5 className="mb-1">{props.reservationItem.checkIn.date}</h5>
                                                        <small><i className="bi bi-alarm me-1"></i>after {props.reservationItem.checkIn.time}</small>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="bg-light py-3 px-4 rounded-3">
                                                        <h6 className="fw-light small mb-1"> Check-Out</h6>
                                                        <small>{props.reservationItem.checkOut.day}</small>
                                                        <h5 className="mb-1">{props.reservationItem.checkOut.date}</h5>
                                                        <small><i className="bi bi-alarm me-1"></i>before {props.reservationItem.checkOut.time}</small>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="bg-light py-3 px-4 rounded-3">
                                                        <h6 className="fw-light small mb-1">{props.reservationItem.roomsOfThisType} {props.reservationItem.roomType}</h6>
                                                        <small>{props.reservationItem.adults} x Adults, { props.reservationItem.children} x Children, {props.reservationItem.pets} x Pets</small>
                                                        <h5 className="mb-1">{props.reservationItem.adults + props.reservationItem.children + props.reservationItem.pets} Guests</h5>
                                                        <small>{props.reservationItem.totalNights} Nights</small>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                <div>
                                                <ul className="list-group list-group-borderless mb-3">
                                                    <li className="list-group-item px-2 d-flex justify-content-between">
                                                        <span className="h6 fw-light mb-0">{props.reservationItem.avarageNightPrice} ILS x {props.reservationItem.totalNights} Nights</span>
                                                        <span className="h6 fw-light mb-0">{props.reservationItem.totalPrice} ILS</span>
                                                    </li>
  
                                                    {props.reservationItem.discount.map(discount => (
                                                        <li class="list-group-item px-2 d-flex justify-content-between">
                                                            <span class="h6 fw-light mb-0">{discount.rate}% {discount.desctiption}</span>
                                                            <span className="badge text-bg-danger smaller mb-0 ms-2">{discount.rate}% off</span>

                                                            <span class="h6 fw-light mb-0">- ₪ {discount.value} ILS</span>
                                                        </li>
                                                    ))}

                                                    <li className="list-group-item px-2 d-flex justify-content-between">
                                                        <span className="h6 fw-light mb-0">Cleaning Fee</span>
                                                        <span className="h6 fw-light mb-0">{props.reservationItem.cleaningFee}</span>
                                                    </li>
                                                    <li className="list-group-item px-2 d-flex justify-content-between">
                                                        <span className="h6 fw-light mb-0">17% Vat for Israeli Citizens</span>
                                                        <span className="h6 fw-light mb-0">{props.reservationItem.vat}</span>
                                                    </li>
                                                    <li className="list-group-item bg-light d-flex justify-content-between rounded-2 px-2 mt-2">
                                                        <span className="h5 fw-normal mb-0 ps-1">Total inc. VAT</span>
                                                        <span className="h5 fw-normal mb-0">{props.reservationItem.netPrice + props.reservationItem.cleaningFee}  ILS</span>
                                                    </li>
                                                </ul>
                                  
                                                </div>

                             
                                            </div>
                                            
        </div>
        </>
);
    }

    export default RoomBooking;