
import React from 'react';
//import { useState } from 'react';
import RoomBooking from './RoomBooking';
import AreaDialCode from '../Forms/AreaDialCode';
import './booking.css';
import CreditCard from './Payment/creditcard';
import axios from 'axios';
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const apiUrl = config.apiUrl;
const imagesUrl = config.imagesUrl;




class PassportId extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    render(){

        //handleInputChange
        if (this.props.israeli === 0){
            return(
                <>
                    <div className="col-md-6">
                        <label className="form-label">Passport Country</label>
                        <input type="text" name="country" className="form-control" value={this.props.country} onChange={(e) => this.props.handleInputChange(e)} placeholder="Enter your country origin" />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Passport Number</label>
                        <input type="text" name="passport" className="form-control" value={this.props.passport} onChange={(e) => this.props.handleInputChange(e)}  placeholder="Enter your passport number" />
                    </div>
    
                </>
                
    
            );
        } else {
            return (
                <>
                    <div className="col-md-6">
                        <label className="form-label">City</label>
                        <input type="text" name="city" className="form-control" value={this.props.city} onChange={(e) => this.props.handleInputChange(e)}  placeholder="Enter city you live" />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">ID Number</label>
                        <input type="text" name="ID" className="form-control"  value={this.props.ID} onChange={(e) => this.props.handleInputChange(e)} placeholder="Enter your Israeli Id number" />
                    </div>
    
                </>
            )
        }
        

    }

}




class NewReservation extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        activeStep: 1,
        stepStatus: ['step active', 'step', 'step'], 
        stepTab: [' active dstepper-block', '', ''],
        displayTab: ['block', 'none', 'none'],
        paymentTab: ['none', 'none', 'none', 'none', 'none', 'none'],
        country: 'Israel',
        state: '',
        city: '',
        street: '',
        malon:{
            id: 1,
            name: 'Prince palace Netanay South Beach'
        },
        reservationLine: {},
        reservationItems: [],
        
        reservationItems1:[
            {
                reservationItemId: 1,
                malon: {id:1, name:'Prince Palace Netanya South Beach'},
                room: {id: 1, name: 'Royal Palace 2BR', type:'Apartment', description: '100m, 2BR, Balcony, Sea View, No Smoking', rate:4.5},
                checkIn: {date: '2 July 2023', day: 'Sunday', time: '1pm'},
                checkOut: {date: '5 July 2023', day: 'Wednesday', time: '11am'},
                nights: 3,
                adults: 2,
                children: 1,
                pets: 0,
                avarageNight: 1759,
                cleaningFee: 299,
                disount: 0,
                total: 5576,
                roomsOfThisType: 1,
                roomType: 'Apartment'
            },
            {
                reservationItemId: 2,
                malon: {id:1, name:'Prince Palace Netanya South Beach'},
                room: {id: 2, name: 'Junior Suite Platinium', description: '50m, 1BR, Kitchenate, real bath, No Smoking', rate:4.5},
                checkIn: {date: '01/06/2023', day: 'Sunday', time: '1pm'},
                checkOut: {date: '08/06/2023', day: 'Sunday', time: '11am'},
                nights: 7,
                adults: 2,
                children: 0,
                pets: 0,
                avarageNight: 799,
                cleaningFee: 199,
                disount: 1000,
                total: 6299,
                roomsOfThisType: 1,
                roomtype: 'Apartment'
            }
        ],
        totalReservationItems: 1,
        israeli: 1,
        vat: 500,
        currency: 'ils',
        title: 'Mr',
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '972',
        phone: '',
        cityName: '',
        countryName: '',
        passport: '',
        ID: '',
        guestComment: ''
        };

        this.selectStep = this.selectStep.bind(this);
        this.handleSbumit = this.handleSubmit.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitNewGuest2Server = this.submitNewGuest2Server.bind(this);
    }



    selectStep(number){
        //set stepStatus
            let tempStatus = ['step', 'step', 'step'];
            tempStatus[number-1] = 'step active';
            this.setState({stepStatus: tempStatus});
        //set stepTab
            let tempStepTab = ['', '', ''];
            tempStepTab[number-1] = ' active dstepper-block';
            this.setState({stepTab: tempStepTab});
            let tempDisplay = ['none', 'none', 'none'];
            tempDisplay[number-1] = 'block';
            this.setState({displayTab: tempDisplay});
    }
    
    selectPayment(number){
        let tempDisplay = ['none', 'none', 'none', 'none', 'none', 'none'];
        tempDisplay[number-1] = 'block';
        this.setState({paymentTab: tempDisplay});
    }

    handleSelect(index){
      }
    handleSubmit = () => {
    }

    handleInputChange(event) {
        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
 


        this.setState({
          [name]: value
        });
      }

    moveStep = (stepNumber) => {
        
    }

    submitNewGuest2Server = async() => {
  
        try {
          const res = await axios.post(apiUrl + '/reservation/RegisterNewGuest', {
            address: 'NA',
            city: this.state.city,
            country: this.state.country,
            countryCode: this.state.countryCode,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            fullName: this.state.firstName + ' ' + this.state.lastName,
            guestId: 'XXX',
            phone: this.state.phone,
            title: this.state.title,
            guestId: 'XXX',
            israeli: this.state.israeli,
            ID: this.state.ID,
            passport: this.state.passport,
        
          });
        } catch(err) {
          console.log(err);
        }
    
      };


    step2 = () => {
        if (this.state.israeli === 1){
            this.setState({country: 'Israel'});
        }
        

        //this.handleCurrentGuest().then(this.submitNewGuest2Server());
        this.submitNewGuest2Server();

        //this.handleX().then(this.submitY());

        this.selectStep(2);

    }

    step3 = () => {
        this.selectStep(3);
    }

    getReservationLineFromLocalStorage = () => {
        return localStorage.getItem('reservationLine');
      };

    componentDidMount (){
        let storedReservationLine = this.getReservationLineFromLocalStorage();
        let reservationLine = JSON.parse(storedReservationLine)

        const fetchData = async () => {
            const result = await axios.get(
                   apiUrl + '/reservation/getReservationSummary', {params: {
                          roomId: reservationLine.roomId, 
                          checkIn: reservationLine.checkIn,
                          checkOut: reservationLine.checkOut,
                          adults: reservationLine.adults, 
                          children: reservationLine.children,
                          pets: reservationLine.pets,
                          rooms: 1
                }
              }
      
            ).then(result => {
              let tempReservtaionLines = [];
              tempReservtaionLines.push(result.data);

              this.setState({reservationItems: tempReservtaionLines});


      
      
            });
          };
          fetchData();
        let checkout = reservationLine.checkOut;
    }


    render(){
        return(<>
            <main>  

                <section>
                    <div className="container" style={{border: 1 +'px solid #000000',
                        padding: 10 + 'px',
                        margin: 'auto',
                        borderRadius: 20 + 'px',
                        marginTop: -50 + 'px',
                        width: 90 + '%'}}>
                        <div id="stepper" className="bs-stepper stepper-outline">
                
                            {/*<!-- Step Buttons START --> */}
                            <div className="bs-stepper-header" role="tablist" style={{width: 50 +'%', margin: 'auto'}}>
                                
                            <div className={this.state.stepStatus[0]} data-target="#step-1">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" id="steppertrigger1" aria-controls="step-1"  onClick={() => this.selectStep(1)}>
                                            <span className="bs-stepper-circle">1</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Guest Information</h6>
                                    </div>
                                </div>
                                <div className="line"></div>
                                
                                <div className={this.state.stepStatus[1]} data-target="#step-2">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger2" aria-controls="step-1" onClick={() => this.selectStep(2)}>
                                            <span className="bs-stepper-circle">2</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Reservation Review</h6>
                                    </div>
                                </div>
                                <div className="line"></div>
                
                                
                
                                <div className={this.state.stepStatus[2]} data-target="#step-3">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link step-trigger mb-0" role="tab" id="steppertrigger3" aria-controls="step-3" onClick={() => this.selectStep(3)}>
                                            <span className="bs-stepper-circle">3</span>
                                        </button>
                                        <h6 className="bs-stepper-label d-none d-md-block">Payment</h6>
                                    </div>
                                </div>
    
                            </div>
                            {/*<!-- Step Buttons END --> */}
                
                            {/*<!-- Step content START --> */}
                            <div className="bs-stepper-content p-0 pt-4 pt-md-5" >
                                <div className="row g-4">
                
                                    <div className="col-12">
                                        <form onSubmit={this.handleSubmit}>
                
                                        <div id="step-1" role="tabpanel" className={'content fade ' + this.state.stepTab[0]} aria-labelledby="steppertrigger1" style={{display: `${this.state.displayTab[0]}`}}>
                                        <section className="py-0" style={{marginBottom: 10 + 'px'}}>
                                                        <div className="container">
                                                            <div className="card bg-light overflow-hidden px-sm-5">
                                                                        <div className="card-body">
                                                                            <h1 className="m-0 h4 card-title" style={{fontSize: 20 + 'px !important', textAlign: 'center'}}>Guest Details</h1>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                
                                                <div className="vstack gap-4">
                
                                                    <div className="card shadow">
                                     
                                        
                                            
                                        <div className="card-body p-4">
                                            <form className="row g-4">
                                                <div className="col-12">

             {/*<!-- Alert START --> */}
             <div className="alert alert-info my-4" role="alert">
                                                <a href="sign-up.html" className="alert-heading h6">Login</a> to prefill all details and get access to secret deals
                                            </div>
                                            {/*<!-- Alert END --> */}

                                                    <div className="bg-light rounded-2 px-4 py-3">
                                                        <h6 className="mb-0">Main Guest</h6>
                                                    </div>

                                         
                                                </div>
                                                





                                                
                                                <div className="col-md-2">
                                                    <div className="form-size-lg">
                                                        <label className="form-label">Title</label>
                                                        <select className="form-select js-choice" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} style={{width: 'auto'}}>
                                                            <option key={1} value={'Mr'}>Mr</option>
                                                            <option key={2} value={'Mw'}>Ms</option>
                                                            <option key={3} value={'Mrs'}>Mrs</option>
                                                            <option key={4} value={'Lord'}>Lord</option>
                                                            <option key={5} value={'Prince'}>Prince</option>
                                                            <option key={6} value={'Princess'}>Princess</option>
                                                        </select>
                                                    </div>	
                                                </div>

                                                <div className="col-md-5">
                                                    <label className="form-label">First Name</label>
                                                    <input type="text" value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})} className="form-control" placeholder="Enter your sure name" />
                                                </div>

                                                <div className="col-md-5">
                                                    <label className="form-label">Last Name</label>
                                                    <input type="text" value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})} className="form-control " placeholder="Enter your family name" />
                                                </div>

                                                <div>


                                                </div>
                                                <div className="col-12" style={{display:'flex', margin: 'auto'}}>
                                
                                                <label className="form-label">Citizenship</label>
                                                    <button type="button" className="btn btn-labeled btn-light" width="250px" style={{margin: 20 + 'px'}} onClick={() => this.setState({israeli: 1})}>
                                                        <span className="btn-label">
                                                            <img src={imagesUrl + "/Images/flags/israel.avif"} alt="Israel Flag" width= "50px" height="50px" style={{borderRadius: 50 +'px'}}/>
                                                        </span> Israeli
                                                    </button>
                                                    <button type="button" className="btn btn-labeled btn-light" width="250px" style={{margin: 20 + 'px'}} onClick={() => this.setState({israeli: 0})}>
                                                        <span className="btn-label">
                                                            <img src={imagesUrl + "/Images/flags/world.avif"} alt="tourist" width= "50px" height="50px" style={{borderRadius: 50 +'px'}}/>
                                                        </span> Tourist from rest of the world
                                                    </button>
                                           
                                                </div>


                                                <PassportId israeli={this.state.israeli} country={this.state.country} city={this.state.city} passport={this.state.passport} ID={this.state.ID} handleInputChange={this.handleInputChange}/>

                                                


                                                <div className="col-md-6">
                                                    <label className="form-label">Email</label>
                                                    <input type="email" className="form-control" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}  placeholder="Enter your email" />
                                                    <div id="emailHelp" className="form-text">(Booking voucher will be sent to this email)</div>
                                                </div>


                                                <div className="row" style={{display:'flex'}}>
                                                    <div className="col-md-4">
                                                    <label className="form-label">Area number:</label>
                                                        <AreaDialCode className="form-select js-choice" countryCode={this.state.areaCode} handleInputChange={this.handleInputChange}/>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label className="form-label">Mobile number</label>
                                                        <input type="text" className="form-control" value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})}  placeholder="Enter your mobile number" />
                                                    </div>
                                                </div>

                                            </form>

                                          
                                    

                                          
                                        </div>
                                    </div>

               
                
                                                    <div className="text-end">
                                                        <button type="button"  className="btn btn-primary next-btn mb-0" onClick={() => this.step2()}>Next</button>
                                                    </div>
                                                
                                                </div>
                                            </div>
                
                                            <div id="step-2" role="tabpanel" className={'content fade ' + this.state.stepTab[1]} aria-labelledby="steppertrigger2" style={{display: `${this.state.displayTab[1]}`}}>
                                                <div className="vstack gap-4">
                                                    <section className="py-0">
                                                        <div className="container">
                                                            <div className="card bg-light overflow-hidden px-sm-5">
                                                                        <div className="card-body">
                                                                            <h1 className="m-0 h4 card-title" style={{fontSize: 20 + 'px !important', textAlign: 'center'}}>Review your Booking</h1>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                    </section>
                
                                                    <div className="card shadow">
                                        
                                        
                                        <div className="card-body p-4" style={{padding: '1% !important'}}>
                                           {this.state.reservationItems.map((item) => <RoomBooking key={item.reservationItemId} reservationItem={item} totalReservationItems={this.state.totalReservationItems} />)}
                                            


                                      
                                        </div>
                                        <div className="card-body">
                                                    <h6>General terms and price details:</h6>
                                                    <ul className="list-group list-group-borderless mb-0">
                                                        <li className="list-group-item h6 fw-light d-flex mb-0"><i className="bi bi-patch-check-fill text-success me-2"></i>Include All Fees and tax</li>
                                                        <li className="list-group-item h6 fw-light d-flex mb-0"><i className="bi bi-patch-check-fill text-success me-2"></i>Not include breakfast or meals</li>
                                                        <li className="list-group-item h6 fw-light d-flex mb-0"><i className="bi bi-patch-check-fill text-success me-2"></i>Require to register every guest in every age (from 0) in reservation and every guest charge.</li>
                                                        <li className="list-group-item h6 fw-light d-flex mb-0"><i className="bi bi-patch-check-fill text-success me-2"></i>Additional guests who do not appear in the reservation will additionally be charged a separate and higher fee than when making the reservation</li>
                                                        <li className="list-group-item h6 fw-light d-flex mb-0"><i className="bi bi-patch-check-fill text-success me-2"></i>Non Refundable. On Cancellation, You will not get any refund</li>
                                                        <li className="list-group-item h6 fw-light d-flex mb-0"><i className="bi bi-patch-check-fill text-success me-2"></i>At the time of check-in, a limit will be taken on a credit card for a security deposit and damages in the amount of NIS 3,000. A deposit can be made in cash.</li>

                                                    </ul>
                                                </div>
                                    </div>
                                    <div className="card border mt-4">
                                                <div className="card-header border-bottom">
                                                    <h5 className="card-title mb-0">Special request</h5>
                                                </div>

                                                <div className="card-body">
                                                    <form className="hstack flex-wrap gap-3">
                                                        
                                                        <div style={{width: 100 +'%'}}>
                                                            <label htmlFor="requestInputBox" >You can write your request if you want:</label><br />
                                                            <textarea className="form-control requestInputBox" id="exampleFormControlTextarea1" rows="3" value={this.state.guestComment} onChange={(e) => this.setState({guestComment: e.target.value})}   style={{width:'100%'}}></textarea>

                                                        </div>
                                                    </form>	
                                                </div>
                                            </div>
                                    <div>
                                        <ul className="list-group list-group-borderless mb-3">
                                            <li className="list-group-item bg-light d-flex justify-content-between rounded-2 px-2 mt-2">
                                                <span className="h5 fw-normal mb-0 ps-1">Total inc. VAT for 2 Apartments</span>
                                                <span className="h5 fw-normal mb-0">$13,500</span>
                                            </li>
                                        </ul>
                                    </div>
                                                    
                
                                                    <div className="text-end">
                                                        <button type="button"  className="btn btn-primary next-btn mb-0" onClick={() => this.step3()}>Confirm Reservation</button>

                                                    </div>
                                                </div>
                                            </div>

                                  
                
                                            <div id="step-3" role="tabpanel" className={'content fade ' + this.state.stepTab[2]} aria-labelledby="steppertrigger3" style={{display: this.state.displayTab[2]}}>
                                                <div className="vstack gap-4">
                                                    <section className="py-0">
                                                        <div className="container">
                                                            <div className="card bg-light overflow-hidden px-sm-5">
                                                                        <div className="card-body">
                                                                            <h1 className="m-0 h4 card-title" style={{fontSize: 20 + 'px !important', textAlign: 'center'}}>Payment</h1>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                    </section>
                
                                                    <div className="card shadow">
                                                        <div className="card-header border-bottom" style={{margin: 'auto'}}>
                                                            <button type="button" className="paymentBtn btn btn-outline-info" onClick={() => this.selectPayment(1)}><img className="paymentIconImage" src="/Images/PaymentIcons/credit_cards.png"/><br/>Credit Card</button>
                                                            <button type="button" className="paymentBtn btn btn-outline-info" onClick={() => this.selectPayment(2)}><img className="paymentIconImage" src="/Images/PaymentIcons/bank_transfer.png"/><br/>Bank Transfer</button>
                                                            <button type="button" className="paymentBtn btn btn-outline-info" onClick={() => this.selectPayment(3)}><img className="paymentIconImage" src="/Images/PaymentIcons/paypal2.png"/><br/>PayPal</button>
                                                            <button type="button" className="paymentBtn btn btn-outline-info" onClick={() => this.selectPayment(4)}><img className="paymentIconImage" src="/Images/PaymentIcons/bit.png"/><br/>Bit</button>
                                                            <button type="button" className="paymentBtn btn btn-outline-info" onClick={() => this.selectPayment(5)}><img className="paymentIconImage" src="/Images/PaymentIcons/phone.png"/><br/>Pay By Phone</button>
                                                            <button type="button" className="paymentBtn btn btn-outline-info" onClick={() => this.selectPayment(6)}><img className="paymentIconImage" src="/Images/PaymentIcons/cash.png"/><br/>Cash</button>
                                                        </div>
                            
                                                        <div className="card-body payment-body">
                                                            <div id="creditCard" className="creditCard" style={{display: `${this.state.paymentTab[0]}`}}>
                                                                <CreditCard />
                                                            </div>
                                                            <div id="creditCard" className="creditCard" style={{display: `${this.state.paymentTab[1]}`}}>
                                                                Bank Transfer
                                                            </div>
                                                            <div id="paypal" className="paypal" style={{display: `${this.state.paymentTab[2]}`}}>
                                                                PayPal
                                                            </div>
                                                            <div id="bit" className="bit" style={{display: `${this.state.paymentTab[3]}`}}>
                                                                Bit
                                                            </div>
                                                            <div id="payByPhone" className="payByPhone" style={{display: `${this.state.paymentTab[4]}`}}>
                                                                Pay By Phone
                                                            </div>
                                                            <div id="case" className="cash" style={{display: `${this.state.paymentTab[5]}`}}>
                                                                Cash
                                                            </div>
                                                        </div>
                                                    </div>	
                

                
                                                    <div className="hstack gap-3 flex-wrap justify-content-between">
                                                        <button className="btn btn-secondary prev-btn mb-0">Previous</button>
                                                        <button className="btn btn-primary next-btn mb-0">Next</button>
                                                    </div>
                                                </div>	
                                            </div>
                
                              
                
                                        </form>
                                    </div>
                
                                </div>						
                            </div>
                        </div>
                    </div>
                </section>
            
            </main>
       </>);

    }
}   
        



export default NewReservation;










