import React from 'react';

class PayBy extends React.Component {

    render() {
        return (
            <label>
                Pay By:
                <select value={this.props.payBy} onChange={(e) => this.props.onChange(e)} className="form-select" aria-label="Default select example">
                    <option key={0} value={''}>Select Payer</option>
                    <option key={1} value="Agent"> Agent </option>
                    <option key={2} value="Guest"> Guest </option>
                </select>                        
            </label>            
                          
                          
        );
    }
}

export default PayBy;
