import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


async function handlePhotosSubmit(selectedFiles) {
  if (selectedFiles.length === 0) {
    console.log("Please select at least one file to upload.");
    return;
  }

  const formData = new FormData();

  for (const file of selectedFiles) {
    formData.append("photos", file);
  }

  // Todo: Replace "/api/photos/upload" with the correct server address
  const response = await fetch("/api/photos/upload", {
    method: "POST",
    body: formData,
  });

  if (response.ok) {
    console.log("Files uploaded successfully.");
  } else {
    console.error("Error uploading files.");
  }
}

function UploadImages(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data URI after image is loaded
          onLoad={() => URL.revokeObjectURL(file.preview)}
          alt={file.name}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data URIs to avoid memory leaks, will run on unmount
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const handleUpload = () => {
    handlePhotosSubmit(files);
  };

  return (
    <div>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="dz-message needsclick" style={{ textAlign: 'center' }}>
            <i className="bi bi-upload display-3"></i>
            <p>Drop files here or click to upload.</p>
          </div>
          <div className="dz-preview row g-4">{thumbs}</div>
        </div>
        <p className="small mb-0 mt-2">
          <b>Note:</b> Only JPG, JPEG, and PNG. Our suggested dimensions are 600px * 450px.
          The larger image will be cropped to 4:3 to fit our thumbnails/previews.
        </p>
      </section>
      <button
        className="btn btn-info"
        style={{ display: 'block', margin: 'auto' }}
        onClick={handleUpload}
      >
        Upload Photos
      </button>
    </div>
  );
}

export default UploadImages;