import React from 'react';
import LanguageContext from '../../LanguageContext';

class CheckInOutBox extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      }
     
}
    componentDidMount() {

      }
      
      static contextType = LanguageContext;

render() {

const { translate } = this.context;

       if  (this.props.date === null || this.props.date === 'null'){
          return (
            <>
              
              <h6 class="fw-light small mb-1">{translate('selectYourDates')}</h6>
              <h6 style={{fontFamily: 'sans-serif'}}>{translate('selectYourDates')}</h6>

            </>
          );
       } else{
          const [day, month, year] = this.props.date.split('/');
          const date = new Date(year, month - 1, day);
          // Extract the desired information
          const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });
          const monthName = date.toLocaleString('en-US', { month: 'long' });
          const calendarDate = date.toLocaleString('en-US', { day: 'numeric' });
        
          return (
              <>
            <h6 class="fw-light small mb-1">Check {this.props.check}</h6>
                  <h6 style={{fontFamily: 'sans-serif'}}> {dayOfWeek}</h6>
          <h6 class="mb-0">{day} {monthName} {year}</h6>
              </>              
          );
       }
        
      }
}

export default CheckInOutBox;