import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import './catalogue.css';
import PropertyGalleryGrid  from './PropertyGalleryGrid2';
import config from '../../config';


//const apiUrl = process.env.REACT_APP_TITLE;
const url = config.url;
const imagesUrl = config.imagesUrl;


const Gallery = (props) => {



return(<>
        <div className="mainBannerDesktop row g-2">
        {(props.propertyImages && props.propertyImages.length > 4) ? (
   
            <>
            <div className="col-md-6">
                <a data-glightbox data-gallery="gallery" href="#" data-bs-toggle="modal" data-bs-target="#propertyGalleryModal">
                    <div className="card card-grid-lg card-element-hover card-overlay-hover overflow-hidden" 
                    style={{backgroundImage:`url(${imagesUrl + props.propertyImages[0].filePath + '/' + props.propertyImages[0].fileName})`, 
                    backgroundPosition: 'center left', 
                    backgroundSize: 'cover'}}>
                        <div className="hover-element position-absolute w-100 h-100">
                            <i className="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                        </div>
                    </div>
                </a>
            </div>
            <div className="col-md-6">
                <div className="row g-2"> 
                    <div className="col-12">
                        <a data-glightbox data-gallery="gallery" href="#" data-bs-toggle="modal" data-bs-target="#propertyGalleryModal">
                            <div className="card card-grid-sm card-element-hover card-overlay-hover overflow-hidden" 
                            style={{backgroundImage: `url(${imagesUrl + props.propertyImages[1].filePath + '/' + props.propertyImages[1].fileName})`, 
                            backgroundPosition: 'center left', backgroundSize: 'cover'}}>
                                <div className="hover-element position-absolute w-100 h-100">
                                    <i className="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                                </div>
                            </div>
                        </a>	
                    </div>

                    <div className="col-md-6">
                        <a data-glightbox data-gallery="gallery" href="#" data-bs-toggle="modal" data-bs-target="#propertyGalleryModal">
                            <div className="card card-grid-sm card-element-hover card-overlay-hover overflow-hidden" 
                            style={{backgroundImage:`url(${imagesUrl + props.propertyImages[2].filePath + '/' + props.propertyImages[2].fileName})`, 
                            backgroundPosition: 'center left', backgroundSize: 'cover'}}>
                                <div className="hover-element position-absolute w-100 h-100">
                                    <i className="bi b
                                    i-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                                </div>
                            </div>
                        </a>	
                    </div>

                    <div className="col-md-6">
                        <div className="card card-grid-sm overflow-hidden" 
                        style={{backgroundImage:`url(${imagesUrl + props.propertyImages[3].filePath + '/' + props.propertyImages[3].fileName})`, backgroundPosition: 'center left', backgroundSize: 'cover'}}>
                            <div className="bg-overlay bg-dark opacity-3"></div>



                            <div className="card-img-overlay d-flex h-100 w-100">
                                <h6 className="card-title m-auto fw-light text-decoration-underline"><a href="#" data-bs-toggle="modal" data-bs-target="#propertyGalleryModal" className="text-white">View all photos</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        ) : (props.propertyImages && props.propertyImages.length > 0) && (
            <>
            <div className="col-md-12">
                <a data-glightbox data-gallery="gallery" href="#" data-bs-toggle="modal" data-bs-target="#propertyGalleryModal">
                    <div className="card card-grid-lg card-element-hover card-overlay-hover overflow-hidden" 
                    style={{backgroundImage:`url(${imagesUrl + props.propertyImages[0].filePath + '/' + props.propertyImages[0].fileName})`, 
                    backgroundPosition: 'center left', 
                    backgroundSize: 'cover'}}>
                        <div className="hover-element position-absolute w-100 h-100">
                            <i className="bi bi-fullscreen fs-6 text-white position-absolute top-50 start-50 translate-middle bg-dark rounded-1 p-2 lh-1"></i>
                        </div>
                    </div>
                </a>
            </div>

            </>)
        
        }
</div>

<div className="BannerMobile row g-2">
    <Carousel indicators={false}>
        {props.propertyImages && props.propertyImages.map((malonImage) => (
            <Carousel.Item key={malonImage.id}>
                <a data-glightbox data-gallery="gallery" href="#" data-bs-toggle="modal" data-bs-target="#propertyGalleryModal">
                    <img
                    className="d-block w-100"
                    src={imagesUrl + malonImage.filePath + '/' + malonImage.fileName}
                    alt=""
                    />
                </a>
            </Carousel.Item>
            ))}
    </Carousel>
</div>

<div className="modal fade" id="propertyGalleryModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{maxWidth: 90 + '%'}}>
                  <div className="modal-content">
                    <div className="modal-header">
                    </div>
                    <div className="modal-body">
                    {props.propertyImages && props.propertyImages.length > 0 && (
                            <PropertyGalleryGrid photos={props.propertyImages}/>
                       
                         )}
                     </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
            

                  </div>
                </div>
              </div>

</>)


}

export default Gallery;













